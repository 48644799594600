import  { ReactNode } from "react";
// import { IoMdChatboxes } from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
import { GrSchedules } from "react-icons/gr";
import {
  FaMicrophone,
  FaRegNewspaper,
  FaUser,
  FaWpforms,
} from "react-icons/fa6";
// import { MdOutlinePayments } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";

interface SidebarItemProps {
  icon: ReactNode;
  text: string;
  active?: boolean;
  alert?: boolean;
  path?: string;
  onClick?: () => void; // Add onClick prop
}




export default function Sidebar() {
 //   const [notification, setNotification] = useState({ type: "", message: "" });
  // const navigate = useNavigate();
  const expanded = useSelector(
    (state: { sidebar: { expanded: boolean } }) => state.sidebar.expanded
  );
  
  const signOut = () => {
    // Clear tokens from local storage
    localStorage.removeItem("authenticationToken");
    localStorage.removeItem("refreshToken");

    // Show notification
   // setNotification({ type: "success", message: "Signed out successfully!" });

    // Redirect the user to the sign-in page
    setTimeout(() => {
      window.location.href = "/";
    }, 1000); // Redirect after a brief delay
  };

  return (
    <aside
      className={`h-[90vh] absolute top-0 left-0 z-50 md:relative ${
        expanded ? "" : "w-0 transition-width duration-300"
      }`}
    >
      <nav className="flex flex-col h-full border-r shadow-sm bg-custom-darkblue">
        <div className="flex items-center justify-between p-1 pb-2">
          <div className="bg-white rounded-md">
            <img
              src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
              alt="Logo"
              className={`overflow-hidden transition-all ${
                expanded ? "w-40" : "w-0"
              }`}
            />
          </div>
        </div>

        <ul
          className={`flex-1 px-3 ${
            expanded ? "" : "w-0 scale-0 transition-width duration-300"
          }`}
        >
          <SidebarItem
            icon={<RxDashboard />}
            text="Dashboard"
            path="/"
          />
          <SidebarItem
            icon={<GrSchedules />}
            text="All Classes"
            path="/findclass"
          />
          <SidebarItem
            icon={<GrSchedules />}
            text="Class schedule"
            path="/schedule"
          />
          <SidebarItem icon={<FaRegNewspaper />} text="Papers" path="/papers" />
          <SidebarItem 
            icon={<MdOutlinePayments />}
           text="Payments"
           path="/payments"
           />
          {/* <SidebarItem icon={<IoMdChatboxes />} text="Chat" /> */}
          <SidebarItem
            icon={<FaMicrophone />}
            text="Announcements"
            path="/announcements"
          />
          <SidebarItem icon={<FaWpforms />} text="Forum" />
          <SidebarItemv2
            icon={<FaSignOutAlt />}
            text="Sign out"
            onClick={signOut} // Attach the sign-out function
          />
        </ul>

        <div
          className={`flex p-3 border-t ${
            expanded ? "" : "w-0 scale-0 transition-width duration-300"
          }`}
        >
          <img
            src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
            alt=""
            className="w-10 h-10 rounded-md "
          />
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0 "}
          `}
          >
            <div className="leading-4">
              <h4 className="font-semibold text-white">John Doe</h4>
              <span className="text-sm text-gray-100">johndoe@gmail.com</span>
            </div>
            <FaUser size={20} color="white" />
          </div>
        </div>
      </nav>
    </aside>
  );
}

function SidebarItem({ icon, text, path }: SidebarItemProps) {
  const expanded = useSelector(
    (state: { sidebar: { expanded: boolean } }) => state.sidebar.expanded
  );
  const location = useLocation();

  const isActive = path ? location.pathname === path : false;

  return (
    <Link to={path ?? ""}>
      <li
        className={`
          relative flex items-center py-2.5 px-3 my-1
          font-medium rounded-md cursor-pointer
          transition-colors group
          ${
            isActive
              ? "bg-custom-yellow text-custom-darkblue"
              : "text-white bg-custom-darkblue hover:bg-custom-yellow"
          }
          hover:text-custom-darkblue `}
      >
        {icon}
        <span
          className={`overflow-hidden transition-all ${
            expanded ? "w-52 ml-3" : "w-0 "
          }`}
        >
          {text}
        </span>
      </li>
    </Link>
  );
}

function SidebarItemv2({ icon, text, path, onClick }: SidebarItemProps) {
  const expanded = useSelector(
    (state: { sidebar: { expanded: boolean } }) => state.sidebar.expanded
  );
  const location = useLocation();

  const isActive = path ? location.pathname === path : false;

  return (
    <li onClick={onClick} className={`relative flex items-center py-2.5 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${isActive ? "bg-custom-yellow text-custom-darkblue" : "text-white bg-custom-darkblue hover:bg-custom-yellow hover:text-custom-darkblue"}`}>
      {icon}
      <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0 "}`}>
        {text}
      </span>
    </li>
  );
}