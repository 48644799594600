import React, { useEffect, useState } from "react";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Header from "../../Ui/Templates/Header";
import {
  FaPlus,
} from "react-icons/fa6";
import CommonButton from "../../Ui/Atoms/Buttons";
import AddPaperModal from "../../Popups/AddPaper";
import { IoIosArrowUp } from "react-icons/io";
import { PaperContainer, QuizContainer } from "../../Ui/Organisms/PaperContainer";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";


const PastPaperData = [
  { name: "Past Paper 1", url: "https://example.com/paper1" },
  { name: "Past Paper 2", url: "https://example.com/paper2" },
  { name: "Past Paper 3", url: "https://example.com/paper3" },
  // Add more entries as needed
];

const ModelPaperData = [
  { name: "Model Paper 1", url: "https://example.com/paper1" },
  { name: "Model Paper 2", url: "https://example.com/paper2" },
  { name: "Model Paper 3", url: "https://example.com/paper3" },
  // Add more entries as needed
];

const QuizData = [
  { name: "Quiz 1", url: "/quzzes/paper1" },
  { name: "Quiz 2", url: "/quzzes/paper2" },
  { name: "Quiz 3", url: "/quzzes/paper3" },
  { name: "Quiz 4", url: "/quzzes/paper2" },
  { name: "Quiz 5", url: "/quzzes/paper3" },
  // Add more entries as needed
];

export default function AllPapers() {
  const [showPastPapers, setShowPastPapers] = useState(false);
  const [showModelPapers, setShowModelPapers] = useState(false);
  const [showQuizzes, setShowQuizzes] = useState(false);
  const [showAddPaper, setShowAddPaper] = useState(false);
  // const [pastPaperData, setPastPaperData] = useState([]);
  // const [modelPaperData, setModelPaperData] = useState([]);

  const handleAddPaper = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowAddPaper(show);
  };

  const togglePastPapers = () => {
    setShowPastPapers(!showPastPapers);
  };

  const toggleModelPapers = () => {
    setShowModelPapers(!showModelPapers);
  };

  const toggleQuizzes = () => {
    setShowQuizzes(!showQuizzes);
  };

  useEffect(() => {
    const fetchPastPaperData = async () => {
      try{
        const response = await axios.get(API_ENDPOINTS.GET_ALL_PAPERS+"9806e8f6-1727-4848-9ec7-ace149ecfcdb",{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authenticationToken')}`
        }});
        console.log(response.data);
      }
      catch(err){
        console.log(err);
      }
    }
    const fetchModelPaperData = async () => {
      try{
        const response = await axios.get(API_ENDPOINTS.GET_ALL_PAPERS+"d503b046-38cf-4bd0-a012-e709fc28dfa5",{
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authenticationToken')}`
        }});
        console.log(response.data);
      }
      catch(err){
        console.log(err);
      }
    }
    fetchModelPaperData();
    fetchPastPaperData();
  }, []);

  return (
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="overflow-y-auto w-full pt-[5vh] md:pt-0">
            {/* Scrollable Content */}
            <div className="min-h-screen p-4 bg-blue-50">
              <div className=" md:flex">
                <div className="w-full px-5 sm:px-4 xl:px-5">
                  <div className="p-5 border-2 rounded">
                    <h1 className="text-2xl font-bold text-custom-darkblue">
                      All Papers
                    </h1>
                    <div>
                      You can download all the question papers from here
                    </div>
                    <div className="text-custom-darkblue">Happy learning !</div>
                  </div>
                  <div className="flex ">
                    <div className=" basis-auto">
                    <CommonButton
                    text={"Add Paper"}
                    size="extrasmall"
                    model="blue"
                    icon={FaPlus}
                    onClick={() => handleAddPaper(true)}
                  />
                    </div>
                    <div className=" basis-auto">
                    <CommonButton
                    text={"Add Quiz"}
                    size="extrasmall"
                    model="blue"
                    icon={FaPlus}
                    onClick={() => handleAddPaper(true)}
                  />
                    </div>
                  </div>
                 

                  <div
                    className="flex p-5 mt-10 text-2xl border-2 border-l-8 rounded cursor-pointer xl:text-3xl text-custom-darkblue border-l-custom-darkblue hover:bg-blue-100"
                    onClick={togglePastPapers}
                  >
                    <div className=" basis-full"> Past papers</div>
                    <div className=" basis-auto">
                      <IoIosArrowUp className={`transition-all duration-150 ${showPastPapers ? "rotate-0":"rotate-180"}`}/> 
                    </div>
                  </div>
                  {showPastPapers && (
                    <div className="h-auto overflow-hidden">
                      {PastPaperData.map((paper, index) => (
                        <PaperContainer
                          key={index}
                          name={paper.name}
                          url={paper.url}
                        />
                      ))}
                    </div>
                  )}
                 <div
                    className="flex p-5 mt-10 text-2xl border-2 border-l-8 rounded cursor-pointer xl:text-3xl text-custom-darkblue border-l-custom-darkblue hover:bg-blue-100"
                    onClick={toggleModelPapers }
                  >
                    <div className=" basis-full"> Model papers</div>
                    <div className=" basis-auto">
                      <IoIosArrowUp className={`transition-all duration-150 ${showModelPapers ? "rotate-0":"rotate-180"}`}/> 
                    </div>
                  </div>
                  {showModelPapers && (
                    <div>
                      {ModelPaperData.map((paper, index) => (
                        <PaperContainer
                          key={index}
                          name={paper.name}
                          url={paper.url}
                        />
                      ))}
                    </div>
                
                  )}
                  <div
                    className="flex p-5 mt-10 text-2xl border-2 border-l-8 rounded cursor-pointer xl:text-3xl text-custom-darkblue border-l-custom-darkblue hover:bg-blue-100"
                    onClick={toggleQuizzes }
                  >
                    <div className=" basis-full"> Quzzers</div>
                    <div className=" basis-auto">
                      <IoIosArrowUp className={`transition-all duration-150 ${showQuizzes ? "rotate-0":"rotate-180"}`}/>
                    </div>
                  </div>
                  {showQuizzes && (
                    <div>
                      {QuizData.map((paper, index) => (
                        <QuizContainer
                          key={index}
                          name={paper.name}
                          url={paper.url}
                        />
                      ))}
                    </div>
                
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
      {
        showAddPaper &&
      <AddPaperModal
        onClose={() => handleAddPaper(false)}
        />
      }
    </div>
  );
}


