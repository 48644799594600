import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";

interface AddEventModalProps {
  show: boolean;
  onClose: () => void;
}

interface ClassData {
  classid: string;
  year: string;
  name: string;
}

const classDataArray: ClassData[] = [
  { classid: "1", year: "2024", name: "Theory" },
  { classid: "2", year: "2024", name: "Revision" },
  { classid: "3", year: "2025", name: "Theory" },
  { classid: "4", year: "2025", name: "Revision" },
  { classid: "5", year: "2026", name: "Theory" },
  { classid: "6", year: "2026", name: "Revision" },
];

const AddEventModal: React.FC<AddEventModalProps> = ({ show, onClose }) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const formatDateTime = (dateTime: Date) => {
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const defaultDateTime = new Date();
  const [startDateTime, setStartDateTime] = useState<string>(
    formatDateTime(defaultDateTime)
  );
  const [endDateTime, setEndDateTime] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [isRecurrent, setisRecurrent] = useState<boolean>(true);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);

  const handleStartDateTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDateTime(e.target.value);
  };

  const handleEndDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateTime(e.target.value);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleIsRecurrentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setisRecurrent(e.target.checked);
  };

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedClass(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('submitted')
    try {
      const eventData = {
        title,
        startDateTime,
        endDateTime,
        isRecurrent,
        selectedClass,
      };

      // Call your backend function here
      // await yourBackendFunction(eventData);
      console.log(eventData);

      // Close the modal after a successful submission
      // onClose();
    } catch (error) {
      console.error("Error submitting event:", error);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[50vh]  xl:h-[90vh] h:[50vh] shadow-lg rounded-3xl">
            <div className="">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className="scale-50 "
                />
              </div>
              <h2 className="items-center mx-auto text-lg font-semibold text-center text-blue-700">
                Add new event
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="class"
                    className="block text-sm font-semibold text-gray-600"
                  >
                    Class
                  </label>
                  <select
                    id="class"
                    value={selectedClass || ""}
                    onChange={handleClassChange}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  >
                    <option value="" disabled>
                      Select a class
                    </option>
                    {classDataArray.map((classData) => (
                      <option key={classData.classid} value={classData.name}>
                        {classData.name} - {classData.year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={handleTitleChange}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div className="flex-1 md:flex">
                  <div className=" basis-1/2">
                    <div className="md:mr-4">
                      <label
                        htmlFor="startDateTime"
                        className="block text-sm font-semibold text-gray-600"
                      >
                        Start Datetime
                      </label>
                      <input
                        type="datetime-local"
                        id="startDateTime"
                        value={startDateTime}
                        onChange={handleStartDateTimeChange}
                        className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                      />
                    </div>
                  </div>
                  <div className=" basis-1/2">
                    <div className="md:ml-4">
                      <label
                        htmlFor="endDateTime"
                        className="block text-sm font-semibold text-gray-600"
                      >
                        End Datetime
                      </label>
                      <input
                        type="datetime-local"
                        id="endDateTime"
                        value={endDateTime}
                        onChange={handleEndDateTimeChange}
                        className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center my-4">
                  <label
                    htmlFor="isRecurrent"
                    className="block mr-2 text-sm font-semibold text-gray-600"
                  >
                    Recurrent
                  </label>
                  <input
                    type="checkbox"
                    id="isRecurrent"
                    checked={isRecurrent}
                    onChange={handleIsRecurrentChange}
                    className="p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  />
                </div>

                <div className="items-center mx-auto text-center">
                  <div className="">
                    <button
                      type="submit"
                    >
                      <CommonButton
                      size="small"
                      model="blue"
                      text="Set"
                    />
                    </button>
                  </div>
                  <div className="">
                    <button
                    >
                      <CommonButton
                      size="small"
                      text="Cancel"
                    />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEventModal;
