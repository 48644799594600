import React, { MouseEvent, useEffect, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import { SessionData } from "../Pages/OtherPages/LessonDetails";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";
import { toast, ToastContainer } from "react-toastify";

interface AddSessionProps {
  onClose: () => void;
  selectedSessionData:SessionData | null;
  LessonID:string | undefined;
  setAllSession: React.Dispatch<React.SetStateAction<SessionData[]>>;
}

const initalSessionData = {
    session_name: "",
    topic: "",
    short_description: "",
    session_number: 0,
    is_available_for_students: false,
    is_available_for_logged_user: false,
    content_access_type: "PUBLIC",
    start_time: "",
}



const AddSession: React.FC<AddSessionProps> = ({ onClose,selectedSessionData,LessonID,setAllSession }) => {
    const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
        onClose();
    }
    };
    const [sessionData, setSessionData] = useState(initalSessionData);
    const [error, setError] = useState({
        session_name: "",
        topic: "",
        short_description: "",
        session_number: "",
        is_available_for_students: "",
        is_available_for_logged_user: "",
        content_access_type: "",
        start_time: "",
    });

    useEffect(() => {
        if(selectedSessionData){
            setSessionData({
                session_name: selectedSessionData.session_name || "",
                topic: selectedSessionData.topic || "",
                short_description: selectedSessionData.short_description || "",
                session_number: selectedSessionData.session_number || 0,
                is_available_for_students: selectedSessionData.is_available_for_students || false,
                is_available_for_logged_user: selectedSessionData.is_available_for_logged_user || false,
                content_access_type: selectedSessionData.content_access_type || "PUBLIC",
                start_time: selectedSessionData.start_date_and_time || "",
            });
        }
        else{
            setSessionData(initalSessionData);
        }
    }, [selectedSessionData]);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { id, value,type } = e.target;
            if(type === "checkbox"){
                setSessionData((prev) => ({
                    ...prev,
                    [id]: !(prev[id as keyof typeof sessionData]),
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: "",
                }));
            }
            else{
                setSessionData((prev) => ({
                    ...prev,
                    [id]: value,
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: "",
                }));
            }
        };

    const validateForm = () => {
        let isValid = true;
        const errorObj = {
            session_name: "",
            topic: "",
            short_description: "",
            session_number: "",
            is_available_for_students: "",
            is_available_for_logged_user: "",
            content_access_type: "",
            start_time: "",
        };
        if (sessionData.session_name === "") {
            errorObj.session_name = "Session Name is required";
            isValid = false;
        }
        if (sessionData.topic === "") {
            errorObj.topic = "Topic is required";
            isValid = false;
        }
        if (sessionData.short_description === "") {
            errorObj.short_description = "Description is required";
            isValid = false;
        }
        if (sessionData.session_number === 0) {
            errorObj.session_number = "Session Number is required";
            isValid = false;
        }
        if (sessionData.start_time === "") {
            errorObj.start_time = "Start Time is required";
            isValid = false;
        }
        setError(errorObj);
        return isValid;
    }


    

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(validateForm() && !selectedSessionData){
    try {
        const sessionDetails = {
            data:{
                start_date_and_time: sessionData.start_time,
                session_name: sessionData.session_name,
                topic: sessionData.topic,
                description: sessionData.short_description,
                session_number: sessionData.session_number,
                is_available_for_student: sessionData.is_available_for_students,
                content_access_type: sessionData.content_access_type,
        }
    };
        const response = await axios.post(API_ENDPOINTS.ADD_SESSION+`/${LessonID}`, 
            sessionDetails,
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
            },
        });
        if(response.status === 200){
            setSessionData(initalSessionData);
            setError({
                session_name: "",
                topic: "",
                short_description: "",
                session_number: "",
                is_available_for_students: "",
                is_available_for_logged_user: "",
                content_access_type: "",
                start_time: "",
            });
            toast.success("Session Added Successfully");
            
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    } catch (error) {
        console.error("Error submitting event:", error);
    }
    }
    else if(validateForm() && selectedSessionData){
        try {
            const sessionDetails = {
                data:{
                    start_date_and_time: sessionData.start_time,
                    session_name: sessionData.session_name,
                    topic: sessionData.topic,
                    description: sessionData.short_description,
                    session_number: sessionData.session_number,
                    is_available_for_student: sessionData.is_available_for_students,
                    content_access_type: sessionData.content_access_type,
            }
        };
    
            const response = await axios.patch(API_ENDPOINTS.UPDATE_SESSION+`/${selectedSessionData.session_id}`, 
                sessionDetails,
                {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
                },
            });
            if(response.status === 200){
                setSessionData(initalSessionData);
                setError({
                    session_name: "",
                    topic: "",
                    short_description: "",
                    session_number: "",
                    is_available_for_students: "",
                    is_available_for_logged_user: "",
                    content_access_type: "",
                    start_time: "",
                });
                console.log(response.data);
                toast.success("Session Updated Successfully");
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        } catch (error) {
            console.error("Error submitting event:", error);
        }
    }
    };

    return (
    <>
        <ToastContainer />
        <div className="absolute top-0 left-0 overflow-y-auto inset-0 z-50 flex items-center justify-center backdrop-blur-lg ">
            <div
            className="absolute h-[150vh] inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
            ></div>
            <div className="relative mt-8 overflow-auto px-8 pt-5 my-auto bg-white w-[90vw] xl:w-[40vw] lg:w-[50vw] md:w-[60vw] xl:h-auto  shadow-lg rounded-3xl ">
            <div className="">
                <div className="px-10 md:px-20">
                <img
                    src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                    alt="Logo"
                    className="scale-50 "
                />
                </div>
                <h2 className="items-center mx-auto text-lg font-semibold text-center text-blue-700">
                Add New Session
                </h2>

                <form onSubmit={handleSubmit}>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Session Name
                    </label>
                    <input
                    type="text"
                    id="session_name"
                    value={sessionData.session_name}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.session_name && <p className="text-red-500 text-xs italic">{error.session_name}</p>
                    }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Session Number
                    </label>
                    <input
                    type="number"
                    id="session_number"
                    value={sessionData.session_number}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.session_number && <p className="text-red-500 text-xs italic">{error.session_number}</p>
                    }
                </div>
                
                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Description
                    </label>
                    <input
                    type="text"
                    id="short_description"
                    value={sessionData.short_description}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.short_description && <p className="text-red-500 text-xs italic">{error.short_description}</p>
                    }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Topic
                    </label>
                    <input
                    type="text"
                    id="topic"
                    value={sessionData.topic}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.topic && <p className="text-red-500 text-xs italic">{error.topic}</p>
                    }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="is_available_for_students"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Available for Students?
                    </label>
                    <input
                    type="checkbox"
                    id="is_available_for_students"
                    checked={sessionData.is_available_for_students}
                    onChange={(e)=>handleTitleChange(e)}
                    className="mt-1 focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.is_available_for_students && <p className="text-red-500 text-xs italic">{error.is_available_for_students}</p>
                    }
                </div>

                <div className="py-2 text-base w-full">
                <div className="flex items-start">
                  <label className="font-medium text-left w-full">Content Access Type</label>
                </div>
                  <select
                    id="content_access_type"
                    name="content_access_type"
                    value={sessionData.content_access_type}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md shadow-md"
                  >
                    <option value="PUBLIC">Public</option>
                    <option value="REGISTERED">Registered</option>
                    <option value="PAID">Paid</option>
                  </select>
                  {
                        error.content_access_type && <p className="text-red-500 text-xs italic">{error.content_access_type}</p>
                  }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Start Time
                    </label>
                    <input
                    type="datetime-local"
                    id="start_time"
                    value={sessionData.start_time}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.start_time && <p className="text-red-500 text-xs italic">{error.start_time}</p>
                    }
                </div>
                
                <div className="items-center mx-auto text-center">
                    <div className="">
                        {
                            selectedSessionData ? <button type="submit">
                            <CommonButton size="small" model="blue" text="Update" />
                        </button> :
                    <button type="submit">
                        <CommonButton size="small" model="blue" text="Set" />
                    </button>
                        }
                    </div>
                    <div className="">
                    <button>
                        <CommonButton size="small" text="Cancel" />
                    </button>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </div>
    </>
    );
};

export default AddSession;
