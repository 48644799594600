import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Assuming you are using react-router for navigation
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { FaLock, FaPlus, FaSpinner } from "react-icons/fa6";
import AddSession from "../../Popups/AddSession";
import { FaEdit } from "react-icons/fa";
import MaterialData from "../../Ui/Atoms/MaterialData";
import AddMaterial from "../../Popups/AddMaterial";


export interface SessionData {
  session_name: string;
  session_id: string;
  topic: string;
  short_description: string;
  session_number: number;
  is_available_for_students: boolean;
  is_available_for_logged_user: boolean;
  content_access_type: string;
  start_date_and_time: string;
}

export interface MaterialDataProps {
  material_name: string;
  description: string;
  is_available_for_student: boolean;
  reference: string;
  material_type_id: string;
}

const LessonDetails = () => {
  // const [date, setDate] = useState(new Date());
  const { LessonID } = useParams();
  const [loading, setLoading] = useState(true);
  const { LessonName } = useParams();
  const navigate = useNavigate();
  // const [sections, setSections] = useState<string[]>([]);
  const [showAddSessionModal, setShowAddSessionModal] = useState(false);
  const [showDetailsIndex, setShowDetailsIndex] = useState<number[]>([]);
  const [selectedSessionData, setSelectedSessionData] = useState<SessionData | null>(null);
  const [materialData, setMaterialData] = useState<MaterialDataProps[]>([]);
  const [allSession, setAllSession] = useState<SessionData[]>([]);
  const [AddMaterialModal, setAddMaterialModal] = useState(false);
  const [selectedmaterialData, setSelectedmaterialData] = useState<MaterialDataProps | null>(null);
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);

  useEffect(() => {
    const fetchAllSessions = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.GET_ALL_SESSIONS_BY_LESSONID + `/${LessonID}`,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
          },
        }
        );
        setAllSession(response.data.data);
        console.log("All sessions:", response.data.data);
      } catch (error) {
        console.error("Error fetching all sessions:", error);
      }
    };

    fetchAllSessions();
  }, [LessonID]);

  const handleNewSessionModal = () => {
    setShowAddSessionModal(true);
  };

  const handleAddSessionClose = () => {
    setShowAddSessionModal(false);
  };

  const handleAddMaterialModal = () => {

    setAddMaterialModal(true);
  }

  const handleAddMaterialClose = () => {
    setAddMaterialModal(false);
  }

  useEffect(() => {
    const token = localStorage.getItem("authenticationToken");

    const validateToken = async () => {
      if (!token) {
        navigate("/signin");
      } else {
        try {
          const isValid = await fakeTokenValidation(token); 
          if (!isValid) {
            navigate("/signin"); 
          } else {
            setLoading(false);
          }
        } catch (error) {
          navigate("/signin");
        }
      }
    };

    validateToken();
  }, [navigate]);

  const fakeTokenValidation = async (token: string) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.VALIDATE_TOKEN + `token=${token}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Token validation error:", error);
      return false;
    }
  };

  const showDetails = (index: number) => () => {
    const isAlreadyShown = showDetailsIndex.includes(index);
    if (isAlreadyShown) {
      setShowDetailsIndex(showDetailsIndex.filter((i) => i !== index));
    } else {
      setShowDetailsIndex([...showDetailsIndex, index]);
    }
  };

  return (
    <>
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="overflow-y-auto w-full pt-[5vh] md:pt-0  bg-gray-200">
            <div className="px-5 sm:px-8 md:px-16 py-1 md:py-5">
              <div className="pl-2 lg:flex min-h-screen">
                <div className="mr-5 basis-auto lg:basis-full p-4 md:p-8 bg-white rounded-xl ">
                  {
                    loading ? <div className="w-full h-full flex items-center justify-center">
                        <FaSpinner className="text-4xl text-custom-darkblue animate-spin"/>
                      </div>:
                    <>
                  <div className="flex items-center justify-between pb-4">
                    <h1 className="pb-2 text-3xl bg-white font-bold text-custom-darkblue">
                      {LessonName} Details
                    </h1>
                    <button
                        className="bg-custom-darkblue hover:scale-105 transition-all duration-200 text-white md:px-4 px-2 py-1 md:py-3 rounded-xl font-semibold"
                        onClick={
                          () => {
                            setSelectedSessionData(null);
                            handleNewSessionModal();
                          }
                        }
                        >
                        Add New Session
                      </button>
                  </div>
                    <div className="flex flex-col gap-2">
                    {
                      (allSession?.length === 0 || allSession === null) && <div className="text-center">No Lesson details found</div>
                    }
                      {allSession?.map((detail, index) => (
                        <div key={index} className="flex flex-col gap-2" >
                          <div className="w-full min-h-20 cursor-pointer flex items-center hover:scale-[1.01] transition-all duration-200 px-5 bg-white shadow-md shadow-blue-100 border-[0.5px] border-blue-100 rounded-md " 
                            >
                            <div className="w-full flex items-center min-h-20"
                              onClick={showDetails(index)}
                              >
                              <div className="flex items-center gap-3">
                                <MdOutlineKeyboardArrowRight
                                  className={`text-2xl text-black transition-all duration-150 ${
                                    showDetailsIndex.includes(index)
                                    ? "rotate-90"
                                    : "rotate-0"
                                  }`}
                                  />
                                <p className="text-black text-2xl font-sans font-bold">{detail.session_name}</p>
                              </div>
                                <div className="text-black font-sans font-semibold ml-auto flex gap-2 items-center">
                                
                                  <p>Not Started</p>
                                  {detail.is_available_for_students ? "" : <FaLock />}
                                </div>
                            </div>
                            <FaEdit className="relative transition-all z-10 ml-2 duration-200 hover:scale-125 cursor-pointer  text-2xl text-blue-500" 
                                onClick={
                                  () => {
                                    setSelectedSessionData(detail);
                                    handleNewSessionModal();
                                  }}/> 
                            <FaPlus className="relative transition-all z-10 ml-2 duration-200 hover:scale-125 hover:rotate-180 cursor-pointer  text-2xl text-blue-500" 
                                onClick={
                                  () => {
                                    setSelectedSessionId(detail.session_id);
                                    setSelectedmaterialData(null);
                                    handleAddMaterialModal();
                                  }}/>
                          </div>
                          {detail.is_available_for_students &&
                          <AnimatePresence mode="wait">
                            {showDetailsIndex.includes(index) && (
                              <motion.div
                              initial={{ height: 0 }}
                              animate={{ height: "auto"}}
                              exit={{ height: 0}}
                              transition={{ duration: 0.5 }}
                              key={`slide-${index}`}
                              className="overflow-hidden"
                              >
                                <MaterialData
                                setSelectedSessionId={setSelectedSessionId} 
                                handleAddMaterialModal={handleAddMaterialModal} 
                                session_id={detail.session_id}
                                materialData={materialData} 
                                setMaterialData={setMaterialData} 
                                setSelectedmaterialData={setSelectedmaterialData} />
                              </motion.div>
                            )}
                          </AnimatePresence>
                          }
                          
                        </div>
                      ))}
                    </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
    {
      AddMaterialModal &&
      <AddMaterial onClose={handleAddMaterialClose} selectedmaterialData={selectedmaterialData} selectedSessionId={selectedSessionId}/>
    }
    {
      showAddSessionModal &&
    <AddSession onClose={handleAddSessionClose} setAllSession={setAllSession} selectedSessionData={selectedSessionData} LessonID={LessonID}/>
    }
    </>
  );
};

export default LessonDetails;

