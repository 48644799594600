import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Assuming you are using react-router for navigation
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import 'react-calendar/dist/Calendar.css';
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import LessonsOptions from "../../Ui/Organisms/LessonsOptions";
import AddLessonByClass from "../../Popups/AddLessonByClass";
import PaymentModal from "../../Popups/PaymentModal";
import { FaSpinner } from "react-icons/fa6";

export interface LessonData {
  id: string;
  lesson_name: string;
  content_access_type: string;
  price: number;
  image: string;
  class_id: string;
  start_date: string;
  end_date: string;
  description: string;
  lesson_number: number;
  lesson_duration: number;
  lesson_description: string;
}

const ClassLessons = () => {
  const [loading, setLoading] = useState(true);
  const { ClassName,classID } = useParams();
  const navigate = useNavigate(); 
  const [lessonsDataArray, setLessonsDataArray] = useState<LessonData[]>([]);
  const [showAddLessonModal, setShowAddLessonModal] = useState(false);
  const [selectedLessonData, setSelectedLessonData] = useState<LessonData | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useEffect(() => {
    const fetchClassData = async () => {
          try {
            const response = await axios.get(API_ENDPOINTS.GET_LESSON_By_CLASSID + `/${classID}`,{
              headers: {
                "Authorization" : `Bearer ${localStorage.getItem("authenticationToken")}`
              }
            });
            const result = await response.data;
              setLessonsDataArray(result.data);
              console.log("lessons in class",result.data);
          } catch (err) {
            console.error("Fetch error:", err);
          } finally {
            setLoading(false);
          }
    }
    fetchClassData();
  }, [classID]);
  const handleNewLessonModal = () => {
    setShowAddLessonModal(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("authenticationToken");
    const validateToken = async () => {
      if (!token) {
  
        navigate("/signin");
      } else {
        try {
        
          const isValid = await fakeTokenValidation(token); 
          if (!isValid) {
            navigate("/signin"); 
          } else {
            setLoading(false); 
          }
        } catch (error) {
          navigate("/signin"); 
        }
      }
    };

    validateToken();
  }, [navigate]);

  const fakeTokenValidation = async (token: string) => {
    try {
      const response = await axios.get(API_ENDPOINTS.VALIDATE_TOKEN+`token=${token}`);
      return response.data.data;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  };

  const handlePaymentModal = () => {
    setShowPaymentModal(true);
  };


  return (
    <>
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="overflow-y-auto w-full pt-[5vh] md:pt-0  bg-gray-200">
            <div className="px-5 sm:px-8 md:px-16 py-1 md:py-5">
              <div className="pl-2 lg:flex min-h-screen">
                <div className="mr-5 basis-auto lg:basis-full p-4 md:p-8 bg-white rounded-xl ">
                  {
                    loading ?  <div className="w-full h-full flex items-center justify-center">
                    <FaSpinner className="text-4xl text-custom-darkblue animate-spin"/>
                  </div>:
                      <>
                      <div className="flex justify-between items-center mb-3">

                      <h1 className="pb-2 text-3xl font-bold text-custom-darkblue">
                          {ClassName} Lessons
                        </h1>
                        <button
                        className="bg-custom-darkblue hover:scale-105 transition-all duration-200 text-white md:px-4 px-3 py-2 md:py-3 rounded-xl font-semibold"
                        onClick={
                          () => {
                            handleNewLessonModal();
                            setSelectedLessonData(null);
                          }
                        }
                        >
                        Add New Lesson
                      </button>
                      </div>
                        <div className="flex flex-col gap-2">
                        {
                      (lessonsDataArray?.length === 0 || lessonsDataArray === null) && <div className="text-center">No Lessons found</div>
                    }
                          <LessonsOptions LessonsDataArray={lessonsDataArray} classID={classID} handleNewLessonModal={handleNewLessonModal} setSelectedLessonData={setSelectedLessonData} handlePaymentModal={handlePaymentModal}/>
                        </div>
                        </>
                  }
                  </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddLessonByClass show={showAddLessonModal}  onClose={() => setShowAddLessonModal(false)} classID={classID ?? null} selectedLessonData={selectedLessonData}/>
    {
      showPaymentModal &&
      <PaymentModal onClose={()=>setShowPaymentModal(false)} selectedClassData={null} selectedLessonData={selectedLessonData}/>
    }
    </>
  );
};

export default ClassLessons;
