import { useState } from "react";
import axios from "axios"; 
import { Link } from "react-router-dom";
// import NavBar from "../../Ui/Templates/NavBar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import CommonButton from "../../Ui/Atoms/Buttons";
import { FaRegUser } from "react-icons/fa6";
import { MdLockOutline, MdMailOutline } from "react-icons/md";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [userEmailOrMobile, setUserEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const determineSignUpMethod = () => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmailOrMobile);
    const isMobileNumber = /^(\+?\d{2} ?\d{1,4} ?\d{1,4} ?\d{1,4})$/.test(
      userEmailOrMobile
    );

    if (isEmail) {
      signUpWithEmail();
    } else if (isMobileNumber) {
      signUpWithMobile();
    } else {
      console.error("Invalid email or mobile number");
    }
  };

  const signUpWithEmail = async () => {
    try {
      const response = await axios.post(API_ENDPOINTS.REGISTER, {
        firstName: firstname,
        lastName: lastname,
        email: userEmailOrMobile,
        password: password,
      });

      // Save tokens in localStorage
      localStorage.setItem("authenticationToken", response.data.data.authenticationToken);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      console.log("Signup successful", response.data);
      
      navigate('/');
      // Optionally redirect the user to another page after signup
      // history.push('/somepage'); // If using react-router
    } catch (error) {
      console.error("Signup failed", error);
      // Handle the error, e.g., show an error message
    }
  };

  const signUpWithMobile = async () => {
    try {
      const response = await axios.post(API_ENDPOINTS.REGISTER, {
        firstName: firstname,
        lastName: lastname,
        mobile: userEmailOrMobile,
        password: password,
      },{
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      // Save tokens in localStorage
      localStorage.setItem("authenticationToken", response.data.data.authenticationToken);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      navigate('/');

      console.log("Signup successful", response.data);
    } catch (error) {
      console.error("Signup failed", error);
    }
  };

  const handleSignUp = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    determineSignUpMethod();
  };


  return (
    <div>
      {/* <div className="w-full ">
        <NavBar />
      </div> */}

      <div className="flex flex-col md:flex-row">
        <div className="flex">
          <img
            src={`${process.env.PUBLIC_URL}/images/signinimg.png`}
            alt="SigninPicture"
            className="z-10 mx-auto max-w-[0vw] md:max-w-full"
          />
        </div>
        <div className="basis-2/3 pt-6">
          <img
            src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
            alt="Logo"
            className=" mx-auto max-w-[50vw] md:max-w-sm"
          />
          <h1 className="text-lg font-bold text-center text-custom-lightblue md:text-3xl">
            Start your journey with us!
          </h1>

          <div className="container px-12 pb-10 mx-auto mt-8 md:px-36">
            <form onSubmit={handleSignUp}>
              <div className="mb-4">
                <label
                  htmlFor="firstname"
                  className="block text-lg font-medium text-gray-900"
                >
                  First Name
                </label>
                <div className="relative">
                  <FaRegUser className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-4" />
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="Type your first name"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100 focus:outline-none focus:ring focus:border-blue-300"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastname"
                  className="block text-lg font-medium text-gray-900"
                >
                  Last Name
                </label>
                <div className="relative">
                  <FaRegUser className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-4" />
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Type your last name"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100 focus:outline-none focus:ring focus:border-blue-300"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="userEmailOrMobile"
                  className="block text-lg font-medium text-gray-900"
                >
                  Email or Mobile Number
                </label>
                <div className="relative">
                  <MdMailOutline className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-5" />
                  <input
                    type="text"
                    id="userEmailOrMobile"
                    name="userEmailOrMobile"
                    placeholder="Type your email or mobile number"
                    value={userEmailOrMobile}
                    onChange={(e) => setUserEmailOrMobile(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100 focus:outline-none focus:ring focus:border-blue-300"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block text-lg font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="relative">
                  <MdLockOutline className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-5" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Type your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100 focus:outline-none focus:ring focus:border-blue-300"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute text-gray-500 transform -translate-y-1/2 right-2 top-1/2"
                  />
                </div>
              </div>

              <button type="submit">
                <CommonButton size="large" model="blue" text="Sign up" />
              </button>
            </form>
            <p className="mt-4">
              Already a member?{" "}
              <Link to="/signin" className="text-blue-700">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>

      <FooterWithSitemap />
    </div>
  );
};

export default SignUp;
