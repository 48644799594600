import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";
import { MaterialDataProps } from "../Pages/OtherPages/LessonDetails";

interface AddMaterialProps {
  onClose: () => void;
    selectedmaterialData:MaterialDataProps | null;
    selectedSessionId: string | null;
}

const initalmaterialData = {
    material_name: "",
    description: "",
    is_available_for_student: false,
    reference: "",
    material_type_id: "",
}



const AddMaterial: React.FC<AddMaterialProps> = ({ onClose,selectedmaterialData,selectedSessionId}) => {
    const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
        onClose();
    }
    };
    const [materialData, setMaterialData] = useState(initalmaterialData);
    const [error, setError] = useState({
        material_name: "",
        description: "",
        reference: "",
        material_type_id: "",
        is_available_for_student: "",
    });

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { id, value,type } = e.target;
            if(type === "checkbox"){
                setMaterialData((prev) => ({
                    ...prev,
                    [id]: !materialData.is_available_for_student as boolean,
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: "",
                }));
            }
            else{
                setMaterialData((prev) => ({
                    ...prev,
                    [id]: value as string,
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: "",
                }));
            }
        };

    const validateForm = () => {
        let isValid = true;
        const errorObj = {
            material_name: "",
            description: "",
            reference: "",
            material_type_id: "",
            is_available_for_student: "",
        };
        if (materialData.material_name === "") {
            errorObj.material_name = "Material Name is required";
            isValid = false;
        }
        if (materialData.description === "") {
            errorObj.description = "Description is required";
            isValid = false;
        }
        if (materialData.reference === "") {
            errorObj.reference = "Reference is required";
            isValid = false;
        }
        if (materialData.material_type_id === "") {
            errorObj.material_type_id = "Material Type is required";
            isValid = false;
        }
        if (materialData.is_available_for_student === undefined) {
            errorObj.is_available_for_student = "Availability is required";
            isValid = false;
        }

        setError(errorObj);
        return isValid;
    }


    

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(validateForm()){
    try {
        const sessionDetails = {
            data:{
                material_name: materialData.material_name,
                description: materialData.description,
                is_available_for_student: true,
                reference: materialData.reference,
                material_type_id: materialData.material_type_id,
        }
    };

        const response = await axios.post(API_ENDPOINTS.ADD_SESSION+`/${""}`, 
            sessionDetails,
            {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
            },
        });
        if(response.status === 200){
            setMaterialData(initalmaterialData);
            setError({
                material_name: "",
                description: "",
                reference: "",
                material_type_id: "",
                is_available_for_student: "",
            });
            onClose();
        }
    } catch (error) {
        console.error("Error submitting event:", error);
    }
}
    };

    return (
    <>
        <div className="absolute top-0 left-0 overflow-y-auto inset-0 z-50 flex items-center justify-center backdrop-blur-lg ">
            <div className="absolute h-[150vh] inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}></div>
            <div className="relative mt-8 overflow-auto px-8 pt-5 my-auto bg-white w-[90vw] xl:w-[40vw] lg:w-[50vw] md:w-[60vw] xl:h-auto  shadow-lg rounded-3xl ">
            <div >
                <div className="px-10 md:px-20">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                        alt="Logo"
                        className="scale-50 "
                    />
                </div>
                <h2 className="items-center mx-auto text-lg font-semibold text-center text-blue-700">
                Add New Material
                </h2>

                <form onSubmit={handleSubmit}>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Material Name
                    </label>
                    <input
                    type="text"
                    id="material_name"
                    value={materialData.material_name}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.material_name && <p className="text-red-500 text-xs italic">{error.material_name}</p>
                    }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="title"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Description
                    </label>
                    <input
                    type="text"
                    id="description"
                    value={materialData.description}
                    onChange={(e)=>handleTitleChange(e)}
                    className="w-full p-2 mt-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.description && <p className="text-red-500 text-xs italic">{error.description}</p>
                    }
                </div>

                <div className="mb-4">
                    <label
                    htmlFor="is_available_for_students"
                    className="block text-sm font-semibold text-gray-600"
                    >
                    Available for Students?
                    </label>
                    <input
                    type="checkbox"
                    id="is_available_for_student"
                    checked={materialData.is_available_for_student}
                    onChange={(e)=>handleTitleChange(e)}
                    className="mt-1 focus:outline-none focus:ring focus:border-blue-300"
                    />
                    {
                        error.is_available_for_student && <p className="text-red-500 text-xs italic">{error.is_available_for_student}</p>
                    }
                </div>

                <div className="items-center mx-auto text-center">
                    <div className="">
                    <button type="submit">
                        <CommonButton size="small" model="blue" text="Set" />
                    </button>
                    </div>
                    <div className="">
                    <button>
                        <CommonButton size="small" text="Cancel" />
                    </button>
                    </div>
                </div>
                
                </form>
            </div>
            </div>
        </div>
    </>
    );
};

export default AddMaterial;