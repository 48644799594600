import { Link } from "react-router-dom";
import { MaterialDataProps } from "../../Pages/OtherPages/LessonDetails";
import { FaEdit } from "react-icons/fa";

interface MaterailcomponentProps {
  materialData: MaterialDataProps[];
  setMaterialData: React.Dispatch<React.SetStateAction<MaterialDataProps[]>>;
  setSelectedmaterialData: React.Dispatch<
    React.SetStateAction<MaterialDataProps | null>
  >;
  handleAddMaterialModal: () => void;
  session_id: string;
  setSelectedSessionId: React.Dispatch<React.SetStateAction<string | null>>;
}

const MaterialData: React.FC<MaterailcomponentProps> = ({
  materialData,
  setMaterialData,
  setSelectedmaterialData,
  handleAddMaterialModal,
  setSelectedSessionId,
  session_id,
}) => {
  return (
    <div className="w-full h-auto cursor-pointer gap-2 flex flex-col items-center p-5 bg-white shadow-md shadow-blue-200 border-[0.5px] border-blue-100 rounded-md">
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={index}
          className="w-full min-h-24 h-auto cursor-pointer flex items-center hover:scale-[1.01] transition-all duration-200 px-5 bg-white shadow-md shadow-blue-100 border-[0.5px] border-blue-100 rounded-md"
        >
            <Link to={`/materialcontent/${index}`} className="w-full flex gap-3 items-center">
            <div className="w-12 h-12 bg-sky-600 rounded-md flex items-center justify-center">
              <img src={`${process.env.PUBLIC_URL}/icons/files.png`} alt="" />
            </div>
            <div className="text-black font-sans font-semibold">
              Details {index}
            </div>
          </Link>
          <FaEdit className="relative transition-all z-10 ml-2 duration-200 hover:scale-125 cursor-pointer text-2xl text-blue-500" 
                onClick={
                () => {
                    setSelectedSessionId(session_id);
                    setSelectedmaterialData(null);
                    handleAddMaterialModal();
                }}/>
        </div>
      ))}
    </div>
  );
};

export default MaterialData;
