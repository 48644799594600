import React, { MouseEvent, useEffect, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";
import { LessonData } from "../Pages/OtherPages/Lessons";
import { toast, ToastContainer } from "react-toastify";

interface AddLessonByClassProps {
  show: boolean;
  onClose: () => void;
  classID: string | null;
  selectedLessonData: LessonData | null;
}

interface LessonDetailsError {
  name?: string;
  year?: string;
  lessonNumber?: string;
  lessonDuration?: string;
  fees?: string;
  description?: string;
  startDateTime?: string;
  endDateTime?: string;
}

interface RequestBody {
  request: {
    data: {
      lesson_name: string;
      lesson_number: number;
      description: string;
      start_date: string;
      end_date: string;
      lesson_duration: number;
      class_id: string | null;
      price: number;
      is_available_for_users: boolean;
      content_access_type: string;
    };
  };
  image: File | null;
}

const AddLessonByClass: React.FC<AddLessonByClassProps> = ({ show, onClose,classID,selectedLessonData}) => {
  const [name, setName] = useState<string>("");
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [fees, setFees] = useState<number>(0);
  const [lessonDuration, setLessonDuration] = useState<number>(0);
  const [lessonNumber, setLessonNumber] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [contentAccessType, setContentAccessType] = useState<string>("PUBLIC");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);


  const [lessonDetailsError, setLessonDetailsError] = useState<LessonDetailsError>({});

  const formatDateTime = (dateTime: Date) => {
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0");
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
  
    const defaultDateTime = new Date();
    const [startDateTime, setStartDateTime] = useState<string>(
      formatDateTime(defaultDateTime)
    );
    const [endDateTime, setEndDateTime] = useState<string>("");

    const handleStartDateTimeChange = (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        setStartDateTime(e.target.value);
      };
    
      const handleEndDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDateTime(e.target.value);
      };

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const handleImageRemove = () => {
    setImage(null);
    setImgUrl(null);
  };
  useEffect(() => {
    if (selectedLessonData) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const tomorrowDate = tomorrow.toISOString().split(".")[0];
      setName(selectedLessonData.lesson_name || "");
      setYear(new Date(selectedLessonData.start_date).getFullYear() || new Date().getFullYear());
      setFees(selectedLessonData.price || 0);
      setLessonDuration(selectedLessonData.lesson_duration || 0);
      setLessonNumber(selectedLessonData.lesson_number || 0);
      setDescription(selectedLessonData.lesson_description || "");
      setContentAccessType(selectedLessonData.content_access_type || "PUBLIC");
      setStartDateTime(selectedLessonData.start_date || new Date().toISOString().split(".")[0]);
      setEndDateTime(selectedLessonData.end_date || tomorrowDate);
      setImgUrl('data:image/png;base64,' + selectedLessonData.image || null);
    }else{
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const tomorrowDate = tomorrow.toISOString().split(".")[0];
      setName("");
      setYear(new Date().getFullYear());
      setFees(0);
      setLessonDuration(0);
      setLessonNumber(0);
      setDescription("");
      setContentAccessType("PUBLIC");
      setStartDateTime(new Date().toISOString().split(".")[0]);
      setEndDateTime(tomorrowDate);
      setImgUrl(null);
    }
  }, [selectedLessonData]);


  

  
  const validateForm = () => {
    let isValid = true;
    const errors: any = {};
    if (!name) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!year) {
      errors.year = "Year is required";
      isValid = false;
    }
    if (!lessonNumber) {
      errors.lessonNumber = "Lesson Number is required";
      isValid = false;
    }
    if (!lessonDuration) {
      errors.lessonDuration = "Lesson Duration is required";
      isValid = false;
    }
    if (!fees) {
      errors.fees = "Fees is required";
      isValid = false;
    }
    if (!description) {
      errors.description = "Description is required";
      isValid = false;
    }
    if (!startDateTime) {
      errors.startDateTime = "Start Date Time is required";
      isValid = false;
    }
    if (!endDateTime) {
      errors.endDateTime = "End Date Time is required";
      isValid = false;
    }
    setLessonDetailsError(errors);
    return isValid;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const token: string | null = localStorage.getItem("authenticationToken");

    if (!token) {
      setErrorMessage("Authentication token is missing. Please log in.");
      return;
    }
    if (!classID) {
      setErrorMessage("Class ID is missing. Please select a class.");
      return;
    }
    if(validateForm()){

        const requestBody: RequestBody = {
            request: {
                data:{ 
                    lesson_name: name,
                    lesson_number: lessonNumber,
                    description: description,
                    start_date: startDateTime,
                    end_date: endDateTime,
                    lesson_duration: lessonDuration,
                    class_id: classID,
                    price: fees,
                    is_available_for_users: true,
                    content_access_type: contentAccessType,
                },
            },
            image: image,
        };
        
        const formData = new FormData();
        const jsonBlob = new Blob([JSON.stringify(requestBody.request)], { type: "application/json" });
        if (image) {
      formData.append("image", image);
    }
    formData.append("request", jsonBlob);
    
    try {
        if(!selectedLessonData){
        const response = await axios.post(API_ENDPOINTS.ADD_LESSON, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
        },
    });
    
    const result: { data: string; error?: string } = response.data;
    if (response) {
        toast.success("Class created successfully");
        setSuccessMessage(result.data);
        setErrorMessage(null); // Clear any previous error
        // Reset the form after successful submission
        setName("");
        setYear(new Date().getFullYear());
        setFees(0);
        setImage(null);
        setContentAccessType("PUBLIC");
        setTimeout(() => {
            onClose();
        }, 2000);
        } else {
            setErrorMessage(result.error || "Failed to create class");
            toast.error("Failed to create class");
        }}
        else{
            const response = await axios.patch(API_ENDPOINTS.UPDATE_LESSON + `/${selectedLessonData.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            
            const result: { data: string; error?: string } = response.data;
            if (response) {
                setSuccessMessage(result.data);
                toast.success("Class updated successfully");
                setErrorMessage(null); // Clear any previous error
                // Reset the form after successful submission
                setName("");
                setYear(new Date().getFullYear());
                setFees(0);
                setImage(null);
                setContentAccessType("PUBLIC");
                setTimeout(() => {
                    onClose();
                }, 2000);
                } else {
                    setErrorMessage(result.error || "An error occurred while updating the class.");
                    toast.error("An error occurred while updating the class.");
                }
        }
        } catch (error) {
            setErrorMessage("An error occurred while creating the class.");
            toast.error("An error occurred while creating the class.");
            console.log("Error:", error);
            console.log("Form Data:", formData);
        }
}
  };

  return (
    <>
      <ToastContainer />
      {show && (
        <div className="absolute top-0 left-0 overflow-y-auto inset-0 z-50 flex items-center justify-center backdrop-blur-lg ">
          <div className="absolute h-[180vh] inset-0 bg-black opacity-40 " onClick={handleOverlayClick}></div>
          <div className="relative mt-5 overflow-auto px-8 pt-5 my-auto bg-white w-[90vw] xl:w-[45vw] lg:w-[50vw] md:w-[60vw] xl:h-auto  shadow-lg rounded-3xl">
            <div>
              <div className="px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                {/* Name Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Lesson Name</label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 pl-4 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.name && <div className="w-full text-left text-red-500">{lessonDetailsError.name}</div>
                  }
                </div>

                {/* Year Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Year</label>
                  </div>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    className="w-full p-2 pl-4 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.year && <div className="w-full text-left text-red-500">{lessonDetailsError.year}</div>
                  }
                </div>
                {/* lesson number Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Lesson Number</label>
                  </div>
                  <input
                    type="number"
                    id="lessonNumber"
                    name="lessonNumber"
                    value={lessonNumber}
                    onChange={(e) => setLessonNumber(parseInt(e.target.value))}
                    className="w-full p-2 pl-4 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.lessonNumber && <div className="w-full text-left text-red-500">{lessonDetailsError.lessonNumber}</div>
                  }
                </div>
                {/* lesson duration Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Duration</label>
                  </div>
                  <input
                    type="number"
                    id="lessonDuration"
                    name="lessonDuration"
                    value={lessonDuration}
                    onChange={(e) => setLessonDuration(parseInt(e.target.value))}
                    className="w-full p-2 pl-4 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.lessonDuration && <div className="w-full text-left text-red-500">{lessonDetailsError.lessonDuration}</div>
                  }
                </div>

                {/* Fees Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Fees</label>
                  </div>
                  <input
                    type="number"
                    id="fees"
                    name="fees"
                    value={fees}
                    onChange={(e) => setFees(parseFloat(e.target.value))}
                    className="w-full p-2 pl-4 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.fees && <div className="w-full text-left text-red-500">{lessonDetailsError.fees}</div>
                  }
                </div>

                {/* description input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Description</label>
                  </div>
                  <textarea
                    id="description"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 pl-4 h-20 mt-1 border rounded-md shadow-md"
                    required
                  />
                  {
                    lessonDetailsError.description && <div className="w-full text-left text-red-500">{lessonDetailsError.description}</div>
                  }
                </div>

                <div className="flex-1 md:flex">
                  <div className=" basis-1/2">
                    <div className="md:mr-4">
                      <label
                        htmlFor="startDateTime"
                        className="block text-sm font-semibold text-gray-600"
                      >
                        Start Datetime
                      </label>
                      <input
                        type="datetime-local"
                        id="startDateTime"
                        min={new Date().toISOString().split(".")[0]}
                        value={startDateTime}
                        onChange={handleStartDateTimeChange}
                        className="w-full p-2 mt-1 border rounded-md shadow-md  focus:outline-none focus:ring focus:border-blue-300"
                      />
                      {
                        lessonDetailsError.startDateTime && <div className="w-full text-left text-red-500">{lessonDetailsError.startDateTime}</div>
                      }
                    </div>
                  </div>
                  
                  <div className=" basis-1/2">
                    <div className="md:ml-4">
                      <label
                        htmlFor="endDateTime"
                        className="block text-sm font-semibold text-gray-600"
                      >
                        End Datetime
                      </label>
                      <input
                        type="datetime-local"
                        id="endDateTime"
                        min={new Date(startDateTime).toISOString().split(".")[0]}
                        value={endDateTime}
                        onChange={handleEndDateTimeChange}
                        className="w-full p-2 mt-1 border rounded-md shadow-md focus:outline-none focus:ring focus:border-blue-300"
                      />
                      {
                        lessonDetailsError.endDateTime && <div className="w-full text-left text-red-500">{lessonDetailsError.endDateTime}</div>
                      }
                    </div>
                  </div>
                </div>

                {/* Content Access Type */}
                <div className="py-2 text-base w-full">
                <div className="flex items-start">
                  <label className="font-medium text-left w-full">Content Access Type</label>
                </div>
                  <select
                    id="contentAccessType"
                    name="contentAccessType"
                    value={contentAccessType}
                    onChange={(e) => setContentAccessType(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md shadow-md"
                  >
                    <option value="PUBLIC">Public</option>
                    <option value="REGISTERED">Registered</option>
                    <option value="PAID">Paid</option>
                  </select>
                </div>

                {/* image Input */}
                <div className="py-2 text-base">
                    {(image || imgUrl) ? (
                      // Display the uploaded image and delete button
                      <div className="flex flex-col items-start gap-1">
                        <label htmlFor="image" className="mr-2 font-medium cursor-pointer">
                            Image Preview
                          </label>
                        <img
                          src={imgUrl ? imgUrl : image ? URL.createObjectURL(image): ""}
                          alt="Preview of uploaded file"
                          className="w-20 h-20 object-cover rounded-md"
                        />
                        <FaTrash className="cursor-pointer text-red-500" onClick={handleImageRemove} />
                      </div>
                    ) : (
                      // Show the upload input when no image is selected
                      <div>
                      <label
                        htmlFor="image"
                        className="flex items-center cursor-pointer px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        <FaUpload className="mr-2" />
                        Upload Image
                      </label>
                      <input
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg, image/jpg"
                        name="image"
                        className="hidden" // Hide the default file input
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            setImage(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                    )}
                  </div>

                {/* Upload Button */}
                {
                  selectedLessonData ? 
                  <div className="mx-auto py-4">
                    <CommonButton size="medium" model="blue" text="Update Class" onClick={(e) => handleSubmit(e as React.FormEvent<HTMLButtonElement>)} />
                  </div>:
                  <div className="mx-auto py-4">
                    <CommonButton size="medium" model="blue" text="Create Class" onClick={(e) => handleSubmit(e as React.FormEvent<HTMLButtonElement>)} />
                  </div>
                }
                
                {/* Display Success or Error Messages */}
                {successMessage && <div className="text-green-600">{successMessage}</div>}
                {errorMessage && <div className="text-red-600">{errorMessage}</div>}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddLessonByClass;
