import { useParams } from "react-router-dom";

const MaterialPage = () => {
    const { materialId } = useParams();
  return (
    <div>MaterialPage 
        materialId: {materialId}
    </div>
  )
}

export default MaterialPage