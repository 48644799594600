import { createSlice } from "@reduxjs/toolkit";

export interface SelectedOption {
  questionIndex: number;
  answers: string[];
}

export interface QuizState {
    currentQuizIndex: number;
    selectedOptions: SelectedOption[];
    marks: number;
    quizFinished: boolean;
  }

const initialState: QuizState = {
  currentQuizIndex: -1,
  selectedOptions: [], // Stores the selected answers for each question
  marks: 0,
  quizFinished: false,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    startQuiz(state) {
      state.currentQuizIndex = 0;
      state.quizFinished = false;
      state.marks = 0;
      state.selectedOptions = [];
    },
    setSelectedOption(state, action) {
      const { questionIndex, optionText, questionType } = action.payload;
      const existingSelection = state.selectedOptions.find(
        (selection) => selection.questionIndex === questionIndex
      );

      if (existingSelection) {
        const updatedAnswers =
          questionType === "radio"
            ? [optionText]
            : existingSelection.answers.includes(optionText)
            ? existingSelection.answers.filter((answer) => answer !== optionText)
            : [...existingSelection.answers, optionText];

        existingSelection.answers = updatedAnswers;
      } else {
        state.selectedOptions.push({
          questionIndex,
          answers: [optionText],
        });
      }
    },
    nextQuestion(state, action) {
      state.currentQuizIndex = action.payload;
    },
    previousQuestion(state) {
      state.currentQuizIndex = Math.max(state.currentQuizIndex - 1, 0);
    },
    finishQuiz(state, action) {
      state.marks = action.payload;
      state.quizFinished = true;
    },
    gotoStart(state) {
        state.currentQuizIndex = -1;
    }
  },
});

export const {
  startQuiz,
  setSelectedOption,
  nextQuestion,
  previousQuestion,
  finishQuiz,
    gotoStart,
} = quizSlice.actions;

export default quizSlice.reducer;
