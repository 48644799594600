// src/store/rootReducer.ts

import { combineReducers } from 'redux';
import sidebarReducer, { SidebarState } from './sidebar';
import quizReducer, { QuizState } from "../slice/quizSlice";
interface RootState {
  sidebar: SidebarState;
  quiz: QuizState;
  // Add other state slices here
}

const rootReducer = combineReducers<RootState>({
  sidebar: sidebarReducer,
  quiz: quizReducer,
  // Add other reducers here
} as any); // Add 'as any' to avoid the type error

export default rootReducer;
