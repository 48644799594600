import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";

declare global {
  interface Window {
    payhere: any;
  }
}

interface PayhereWindow extends Window {
  payhere: {
    startPayment: (payment: any) => void;
    onCompleted: (order_id: string) => void;
    onDismissed: () => void;
    onError: (error: any) => void;
  };
}

declare let window: PayhereWindow;

interface PaymentProps {
  firstname: string;
  lastname: string;
  email: string;
  paymentTitle: string;
  amount: string;
  sendUserId: string;
  reciveUserID: string;
  setPaymentSuccess: any;
  setOrderID: any;
  onClose: () => void;
}

interface PayType {
  orderId: string;
  amount: string;
  hash: string;
}

const Payment = ({
  firstname,
  lastname,
  email,
  paymentTitle,
  amount,
  sendUserId,
  reciveUserID,
  setPaymentSuccess,
  setOrderID,
  onClose,
}: PaymentProps) => {
  const [Pay, setPay] = useState<PayType>({
    orderId: "",
    amount: "",
    hash: "",
  });

  const [success, setSuccess] = useState(false);

  const formattedAmount = parseFloat(amount);

  function generateNonce() {
    return btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16))));
  }

  function payHereURL() {
    if (process.env.NODE_ENV === "production") 
        return "/payhere.js";
    else return "/payhere.dev.js";
  }

  useEffect(() => {
    const nonce = generateNonce();
    const script = document.createElement("script");
    script.src = payHereURL();
    script.async = true;
    script.nonce = nonce;
    script.onload = () => {
      console.log("PayHere script loaded");
    };
    script.onerror = () => {
      console.error("Failed to load PayHere script");
    };
    document.body.appendChild(script);
   
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (success) {
      console.log("Payment success");
    }
  }, [success]);

  const paymentDetails = {
    order_id: "ItemNo12345",
    amount: 2005.00,
    currency: "LKR",
    first_name: "Saman",
    last_name: "Perera",
    email: "samanp@gmail.com",
    phone: "0771234567",
    address: "No.1, Galle Road",
    city: "Colombo",
    country: "Sri Lanka",
  };

  useEffect(() => {
    axios
      .put(API_ENDPOINTS.CREATE_PAYMENT, null, {
        params: { amount: formattedAmount }, // Send amount as a request parameter
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setPay(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formattedAmount]);

  const payment = {
    sandbox: true, // Use sandbox for testing
    merchant_id: "1229183",
    return_url: "http://localhost:3000/payment/success", // Replace with your return URL
    cancel_url: "http://localhost:3000/payment/cancel", // Replace with your cancel URL
    notify_url:
      "http://localhost:5001/payment/notify", // Replace with your notify URL - This should be public IP (No Localhost)
    order_id: Pay.orderId,
    items: paymentTitle,
    amount: Pay.amount,
    currency: paymentDetails.currency,
    first_name: paymentDetails.first_name,
    last_name: paymentDetails.last_name,
    email: paymentDetails.email,
    phone: paymentDetails.phone,
    address: paymentDetails.address,
    city: paymentDetails.city,
    country: paymentDetails.country,
    hash: Pay.hash,
  };

  const handlePayment = (e:React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
        e.preventDefault();
        window.payhere.startPayment(payment);
        onClose();

        window.payhere.onCompleted = function onCompleted(orderId) {
          console.log("Payment completed. Order ID:", orderId);
          setPaymentSuccess(true);
          setOrderID(orderId);
          setSuccess(true);
        }
      
        window.payhere.onDismissed = function onDismissed() {
          console.log("Payment dismissed");
        }
      
        window.payhere.onError = function onError(error) {
          console.error("An error occurred:", error);
        }
  };

  
  

  return (
    <button
      onClick={(e)=>handlePayment(e)}
      className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Pay with Payhere
    </button>
  );
};

export default Payment;