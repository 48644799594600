import React, { MouseEvent, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import Dropzone from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";

interface UpdateProfilePicProps {
  setUserProfile: (userProfile: any) => void;
  show: boolean;
  onClose: () => void;
}

const UpdateProfilePic: React.FC<UpdateProfilePicProps> = ({
  show,
  onClose,
  setUserProfile
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0]);
  };

    const handleUpload = async(e:React.FormEvent<HTMLButtonElement> ,selectedFile: File) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("userImage", selectedFile);
      try {
        const response = await axios.patch(
          API_ENDPOINTS.UPDATE_USER_PROFILE_PIC,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
            },
          }
        );
        if (response.data.meta.code === 1) {
          window.location.reload();
        }
        setTimeout(() => {
        onClose();
        }, 1000);
      } catch (error) {
        console.log(error);
    }
  };


  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[45vw] lg:w-[50vw] md:w-[60vw] xl:h-auto shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        className="w-full h-[200px] border-dashed border-4 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer"
                      >
                        <input {...getInputProps()} />
                        <div className="items-center justify-center text-center">
                          
                          {selectedFile ? (
                            <div className="flex items-center justify-center mx-auto mb-2">
                              <img src={URL.createObjectURL(selectedFile)} alt="profile" className="w-32"/>
                            </div>

                          )
                            :
                            <FaCloudUploadAlt
                            className="items-center justify-center mx-auto mb-2 text-4xl"
                            color="darkblue"
                          />
                        }
                            {selectedFile ? 
                            <div className="flex items-center justify-center mx-auto gap-2 mb-2">
                              <p className="text-sm text-blue-900">
                                selectedFile.name
                              </p>
                              <FaTrash
                                className="text-md text-red-500 cursor-pointer"
                                onClick={() => setSelectedFile(undefined)}
                              />
                            </div>
                            :
                            <p className="text-sm text-blue-900">
                             'Drop file here, or click to select files'
                            </p>
                             }
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div className="mx-auto ">
                  <button>
                    <CommonButton
                      size="medium"
                      model="blue"
                      text="Upload"
                      onClick={(e) => selectedFile && handleUpload(e, selectedFile)}
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProfilePic;

