interface QuizData {
  id: string;
  title: string;
  description: string;
  totalMarks: number;
  createdBy: string;
  questions: Question[];
  classIds: string[];
  duration: number;
  deadline: string;
}

interface Question {
  questionText: string;
  questionType: string;
  marks: number;
  options: Option[];
  files: File[];
}

interface File {
  fileUrl: string;
  fileType: string;
}

interface Option {
  optionText: string;
  file: File;
  correct: boolean;
}


export const MyQuizData: QuizData = 
  {
      id: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
      title: "This is Paper 1",
      description: "Question description",
      totalMarks: 0,
      createdBy: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
      questions: [
        {
            questionText: "Question 1",
            questionType: "radio",
            marks: 1,
            options: [
                {
                    optionText: "Option 1",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: true
                },
                {
                    optionText: "Option 2",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                },
                {
                    optionText: "Option 3",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                },
                {
                    optionText: "Option 4",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                }
            ],
            files: [
                {
                    fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    fileType: "string"
                }
            ]
        },
        {
            questionText: "Question 2",
            questionType: "radio",
            marks: 1,
            options: [
                {
                    optionText: "Option 1",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: true
                },
                {
                    optionText: "Option 2",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                },
                {
                    optionText: "Option 3",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                },
                {
                    optionText: "Option 4",
                    file: {
                        fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        fileType: "string"
                    },
                    correct: false
                }
            ],
            files: [
                {
                    fileUrl: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    fileType: "string"
                }
            ]
        },
          {
              questionText: "Question 3",
              questionType: "checkbox",
              marks: 1,
              options: [
                  {
                      optionText: "Option 1",
                      file: {
                          fileUrl: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
                          fileType: "string"
                      },
                      correct: true
                  },
                  {
                      optionText: "Option 2",
                      file: {
                          fileUrl: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
                          fileType: "string"
                      },
                      correct: true
                  },
                  {
                      optionText: "Option 3",
                      file: {
                          fileUrl: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
                          fileType: "string"
                      },
                      correct: false
                  },
                  {
                      optionText: "Option 4",
                      file: {
                          fileUrl: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
                          fileType: "string"
                      },
                      correct: true
                  }
              ],
              files: [
                  {
                      fileUrl: "2fan5f64-5717-4562-b3fc-25df3f66afa6",
                      fileType: "string"
                  }
              ]
          }
      ],
      classIds: [
          "2fan5f64-5717-4562-b3fc-25df3f66afa6"
      ],
      duration: 0,
      deadline: "2024-12-24T07:14:51.979Z"
  }
