import React, { MouseEvent, useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import CommonButton from "../Ui/Atoms/Buttons";
import ForgotPasswordModal2 from "./FogotPasswordS2";

interface ForgotPasswordModal1Props {
  show: boolean;
  onClose: () => void;
}

const ForgotPasswordModal1: React.FC<ForgotPasswordModal1Props> = ({
  show,
  onClose,
}) => {
  const [email, setEmail] = useState("");
  const [showFogotPasswordModal2, setShowFogotPasswordModal2] = React.useState(false);
  const [error, setError] = useState("");

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleFogotPasswordModal2 = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    if(email === ""){
      setError("Email is required");
    }
    else{
      setError("");
      onClose();
      setShowFogotPasswordModal2(show);
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] xl:w-[40vw] lg:w-[70vw] md:w-[70vw] lg:h-[80vw] md:h-[50vh]  xl:h-[90vh] h:[50vh] shadow-lg rounded-3xl">
            <div className="p-5 lg:p-14">
              <div className="px-10 md:px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>
              <h2 className="items-center mx-auto my-8 text-2xl font-semibold text-center text-blue-700">
                Forgot Password
              </h2>

              <form>
                <label className="block mb-1 text-sm font-medium text-gray-700">
                  Enter email address
                </label>
                <div className="relative">
                  <MdOutlineMail className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2 size-5" />
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Type your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md bg-slate-100"
                    style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                    required
                  />
                </div>
                  {
                    error && <div className="text-red-500 text-md mt-2">{error}</div>
                  }
                <div className="items-center mx-auto text-center">
                  <div className="pt-5 flex items-center justify-center">
                    <CommonButton
                      size="small"
                      model="blue"
                      text="Send"
                      onClick={() => {
                        
                        handleFogotPasswordModal2(true);
                      }}
                    />
                  </div>
                  <div className="">or</div>
                  <div className="pb-5 flex items-center justify-center">
                    <CommonButton
                      size="small"
                      model="default"
                      text="Cancel"
                      onClick={onClose}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ForgotPasswordModal2
        show={showFogotPasswordModal2}
        onClose={() => handleFogotPasswordModal2(false)}
      />
    </>
  );
};

export default ForgotPasswordModal1;
