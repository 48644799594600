import { Link } from "react-router-dom";
import ClassItem from "../Atoms/ClassItem";
import { FaEdit } from "react-icons/fa";
import { ClassData } from "../../Pages/Lessons/Lessons";
import { PiPaypalLogo } from "react-icons/pi";
import { useSelector } from "react-redux";

interface ClassOptionsProps {
  classDataArray: ClassData[] | undefined;
  handleNewClassModal: () => void;
  setSelectedClassData: (classData: ClassData) => void;
  handlePaymentModal: () => void;
}

export default function ClassesOptions({classDataArray,handleNewClassModal,setSelectedClassData,handlePaymentModal}: ClassOptionsProps) {
  const expanded = useSelector(
      (state: { sidebar: { expanded: boolean } }) => state.sidebar.expanded
    );
  return (
    <div>
      <div className={`grid gap-8   ${expanded ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3":"sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"}`}>
        {
          classDataArray?.map((classData, index) => (
            <div className="flex items-center relative" key={index}>
                <Link to={`/lessons/${classData.class_name}/${classData.id}`} key={index}>
                  <ClassItem name={classData.class_name} image={classData.image} classFee={classData.class_fees}/>
                </Link>
                <div className="flex gap-2 h-full items-center relative">
                <FaEdit className="absolute transition-all duration-200 hover:scale-125 cursor-pointer bottom-7 right-3 text-2xl text-blue-500" 
                onClick={
                  () => {
                    setSelectedClassData(classData);
                    handleNewClassModal();
                  }}/>
                <PiPaypalLogo className="absolute transition-all duration-200 hover:scale-125 cursor-pointer bottom-7 right-10 text-2xl text-blue-500" 
                onClick={
                  () => {
                    setSelectedClassData(classData);
                    handlePaymentModal();
                  }}/>
                  </div>
              </div>
          ))
        }
      </div>
    </div>
  );
}
