import React, { MouseEvent, useEffect, useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import { FaUpload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";
import { ClassData } from "../Pages/Lessons/Lessons";
import { toast, ToastContainer } from "react-toastify";

interface AddClassModalProps {
  onClose: () => void;
  selectedClassData: ClassData | null;
}

const AddClassModal: React.FC<AddClassModalProps> = ({onClose,selectedClassData }) => {
  const [name, setName] = useState<string>("");
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [fees, setFees] = useState<number>(0);
  const [contentAccessType, setContentAccessType] = useState<string>("PUBLIC");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);

  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const handleImageRemove = () => {
    setImage(null);
    setImgUrl("");
  };
  useEffect(() => {
    if (selectedClassData) {
      setName(selectedClassData.class_name || ""); 
      setYear(selectedClassData.year || new Date().getFullYear());
      setFees(selectedClassData.class_fees || 0);
      setContentAccessType(selectedClassData.content_access_type || "PUBLIC");
      setImgUrl('data:image/png;base64,' + selectedClassData.image || null);
      setIsActive(selectedClassData.is_active || true);
    }
    else{
      setName("");
      setYear(new Date().getFullYear());
      setFees(0); 
      setContentAccessType("PUBLIC");
      setImgUrl(null);
      setIsActive(false);
    }
  }, [selectedClassData]);

  interface RequestBody {
    request: {
      data: {
        class_name: string;
        year: number;
        fees: number;
        content_access_type: string;
        is_active: boolean;
      };
    };
    image: File | null;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const token: string | null = localStorage.getItem("authenticationToken");

    if (!token) {
      setErrorMessage("Authentication token is missing. Please log in.");
      return;
    }
    const requestBody: RequestBody = {
      request: {
        data: {
          class_name: name,
          year: year,
          fees: fees,
          content_access_type: contentAccessType,
          is_active: isActive,
        },
      },
      image: image,
    };
    const formData = new FormData();
    const jsonBlob = new Blob([JSON.stringify(requestBody.request)], { type: "application/json" });
    if (image) {
      formData.append("image", image);
    }
    formData.append("request", jsonBlob);
    try {
      
      if(!selectedClassData){
      const response = await axios.post(API_ENDPOINTS.ADD_CLASS, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      const result: { data: string; error?: string } = response.data;
      if (response) {
        setSuccessMessage(result.data);
        toast.success("Class Created Successfully");
        setErrorMessage(null); // Clear any previous error
        // Reset the form after successful submission
        setName("");
        setYear(2023);
        setFees(0);
        setImage(null);
        setContentAccessType("PUBLIC");
        setIsActive(true);
        setTimeout(() => {
            onClose();
        }, 2000);
        
      } else {
        setErrorMessage(result.error || "Failed to create class");
        toast.error("Failed to create class");
      }
    }

    else{
      const response = await axios.patch(`${API_ENDPOINTS.UPDATE_CLASS}/${selectedClassData.id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      const result: { data: string; error?: string } = response.data;
      if (response) {
        setSuccessMessage(result.data);
        toast.success("Class Updated Successfully");
        setErrorMessage(null); // Clear any previous error
        // Reset the form after successful submission
        setName("");
        setYear(2023);
        setFees(0);
        setImage(null);
        setIsActive(true);
        setContentAccessType("PUBLIC");
        setTimeout(() => {
            onClose();
        }, 2000);
        console.log(requestBody)
      } else {
        setErrorMessage(result.error || "Failed to update class");
        toast.error("Failed to update class");
      }
    }
    } catch (error) {
      setErrorMessage("An error occurred while creating the class.");
      toast.error("An error occurred while creating the class.");
      console.log("Error:", error);
      console.log("Form Data:", formData);
    }
  };



  return (
    <>
    <ToastContainer />
        <div className="absolute top-0 left-0 overflow-y-auto inset-0 z-50 flex items-center justify-center backdrop-blur-lg ">
          <div className="absolute h-[110vh] inset-0 bg-black opacity-40 " onClick={handleOverlayClick}></div>
          <div className="relative overflow-auto px-8 pt-5 my-auto bg-white w-[90vw] xl:w-[40vw] lg:w-[50vw] md:w-[60vw] xl:h-auto  shadow-lg rounded-3xl">
            <div>
              <div className="px-20">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className=""
                />
              </div>

              <form className="items-center justify-center text-center">
                {/* Name Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Class Name</label>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={selectedClassData?.class_name || name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* Year Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Year</label>
                  </div>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* Fees Input */}
                <div className="py-2 text-base">
                  <div className="flex items-start">
                    <label className="mr-2 font-medium">Fees</label>
                  </div>
                  <input
                    type="number"
                    id="fees"
                    name="fees"
                    value={fees}
                    onChange={(e) => setFees(parseFloat(e.target.value))}
                    className="w-full p-2 pl-8 mt-1 border rounded-md shadow-md"
                    required
                  />
                </div>

                {/* is Active */}
                <div className="py-2 text-base flex justify-start items-center gap-2">
                  <input
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    required
                  />
                  <label htmlFor="isActive" className="font-medium">Is Active</label>
                </div>

                {/* Content Access Type */}
                <div className="py-2 text-base w-full">
                <div className="flex items-start">
                  <label className="font-medium text-left w-full">Content Access Type</label>
                </div>
                  <select
                    id="contentAccessType"
                    name="contentAccessType"
                    value={contentAccessType}
                    onChange={(e) => setContentAccessType(e.target.value)}
                    className="w-full p-2 mt-1 border rounded-md shadow-md"
                  >
                    <option value="PUBLIC">Public</option>
                    <option value="REGISTERED">Registered</option>
                    <option value="PAID">Paid</option>
                  </select>
                </div>

                {/* image Input */}
                <div className="py-2 text-base">
                    {(image || imgUrl) ? (
                      // Display the uploaded image and delete button
                      <div className="flex flex-col items-start gap-1">
                        <label htmlFor="image" className="mr-2 font-medium cursor-pointer">
                            Image Preview
                          </label>
                        <img
                          src={imgUrl ? imgUrl : image ? URL.createObjectURL(image): ""}
                          alt="Preview of uploaded file"
                          className="w-20 h-20 object-cover rounded-md"
                        />
                        <FaTrash className="cursor-pointer text-red-500" onClick={handleImageRemove} />
                      </div>
                    ) : (
                      // Show the upload input when no image is selected
                      <div>
                      <label
                        htmlFor="image"
                        className="flex items-center cursor-pointer px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                      >
                        <FaUpload className="mr-2" />
                        Upload Image
                      </label>
                      <input
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg, image/jpg"
                        name="image"
                        className="hidden" // Hide the default file input
                        onChange={(e) => {
                          if (e.target.files && e.target.files[0]) {
                            setImage(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                    )}
                  </div>

                {/* Upload Button */}
                {
                  selectedClassData ? <div className="mx-auto py-4">
                    <CommonButton size="medium" model="blue" text="Update Class" onClick={(e) => handleSubmit(e as React.FormEvent<HTMLButtonElement>)} />
                  </div>:
                <div className="mx-auto py-4">
                  <CommonButton size="medium" model="blue" text="Create Class" onClick={(e) => handleSubmit(e as React.FormEvent<HTMLButtonElement>)} />
                </div>
                }
                
                {/* Display Success or Error Messages */}
                {successMessage && <div className="text-green-600">{successMessage}</div>}
                {errorMessage && <div className="text-red-600">{errorMessage}</div>}
              </form>
            </div>
          </div>
        </div>
    </>
  );
};

export default AddClassModal;
