import React from "react";
import { PiBookBookmarkThin, PiChalkboardTeacherThin, PiFileTextThin, PiLaptopThin, PiNotepadThin, PiVideoThin } from "react-icons/pi";


type DashboardItemProps = {
  name: string;
  icon: string;
};

const DashboardItem: React.FC<DashboardItemProps> = ({ name, icon }) => {
  const renderIcon = () => {
    switch (icon) {
      case "lesson":
        return <PiVideoThin size={48} color="white"/>;
      case "syllabus":
        return <PiBookBookmarkThin  size={48} color="white" />;
      case "teacherguide":
        return <PiChalkboardTeacherThin size={48} color="white" />;
      case "paper":
        return <PiFileTextThin size={48} color="white" />;
      case "assignment":
        return <PiNotepadThin size={48} color="white" />;
      case "quiz":
        return <PiLaptopThin size={48} color="white" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex bg-[#3086d2] rounded-md cursor-pointer shadow-xl
     hover:bg-[#488ac3] transition-all duration-200 hover:scale-105 h-full w-full relative  md:min-h-28 xl:min-h-40">
      <div className="flex items-center justify-center basis-1/3">
        {renderIcon()}
      </div>
      <div className="p-5 my-auto text-sm text-white basis-2/3">
        {name}
      </div>
    </div>
  );
};

export default DashboardItem;
