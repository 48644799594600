import{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming you are using react-router for navigation
import Header from "../../Ui/Templates/Header";
import Sidebar from "../../Ui/Templates/Sidebar";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import 'react-calendar/dist/Calendar.css';
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import ClassesOptions from "../../Ui/Organisms/ClassesOptions";
import AddClassModal from "../../Popups/AddClass";
import PaymentModal from "../../Popups/PaymentModal";
import { FaSpinner } from "react-icons/fa6";

export interface ClassData {
  id: string;
  year: number;
  class_fees: number;
  class_name: string;
  content_access_type: string;
  image: string;
  is_active: boolean;
}

const AllClasses = () => {
  const navigate = useNavigate();
  const [classDataArray, setClassDataArray] = useState<ClassData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddClassModal, setShowAddClassModal] = useState(false);
  const [selectedClassData, setSelectedClassData] = useState<ClassData | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("authenticationToken");

    const validateToken = async () => {
      if (!token) {
        navigate("/signin");
      } else {
        try {
          
          const isValid = await fakeTokenValidation(token); 
          if (!isValid) {
            navigate("/signin"); 
          } else {
            setLoading(false); 
          }
        } catch (error) {
          navigate("/signin"); 
        }
      }
    };
    validateToken();
  }, [navigate]);

  const fetchClassData = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.GET_CLASSES,{
        headers: {
          "Authorization" : `Bearer ${localStorage.getItem("authenticationToken")}`
        }
      });
      const result = response.data;
      setClassDataArray(result.data);
      console.log("classes",result);
    } catch (err) {
      console.error("Fetch error:", err);
    } finally {
      setLoading(false);
    }
}
  useEffect(() => {
    fetchClassData();
  }, []);

  const handleAddClassClose = () => {
    setShowAddClassModal(false);
  };

  const handleNewClassModal = () => {
    setShowAddClassModal(true);
  };
  const handlePaymentModal = () => {
    setShowPaymentModal(true);
  };

  
  const fakeTokenValidation = async (token: string) => {
    try {
      const response = await axios.get(API_ENDPOINTS.VALIDATE_TOKEN+`token=${token}`);
      return response.data.data;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  };



  return (
    <>
    <div className="flex-1">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="overflow-y-auto w-full pt-[5vh] md:pt-0  bg-gray-200">
            <div className="px-5 sm:px-8 md:px-16 py-1 md:py-5">
              <div className="pl-2 lg:flex">
                <div className="mr-5 basis-auto lg:basis-full p-4 md:p-8 bg-white rounded-xl min-h-screen">
                  {
                    loading ?  <div className="w-full h-full flex items-center justify-center">
                    <FaSpinner className="text-4xl text-custom-darkblue animate-spin"/>
                  </div>
                    :
                  <>
                    <div className="flex justify-between items-center mb-3">
                      <h1 className="pb-2 text-3xl bg-white font-bold text-custom-darkblue">
                      All Classes
                      </h1>
                      <button
                        className="bg-custom-darkblue hover:scale-105 transition-all duration-200 text-white md:px-4 px-3 py-2 md:py-3 rounded-xl font-semibold"
                        onClick={
                          () => {
                            setSelectedClassData(null);
                            handleNewClassModal();
                          }
                        }
                      >
                        Add New Class
                      </button>
                    </div>
                    {
                      (classDataArray?.length === 0 || classDataArray === null) && <div className="text-center">No classes found</div>
                    }
                    <ClassesOptions classDataArray={classDataArray}  handleNewClassModal={handleNewClassModal} setSelectedClassData={setSelectedClassData} handlePaymentModal={handlePaymentModal}/>
                  </>
                  }
                </div>
                
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
    </div>
    {
    showAddClassModal &&
    <AddClassModal onClose={handleAddClassClose} selectedClassData={selectedClassData}/>
    }
    {
      showPaymentModal &&
      <PaymentModal onClose={()=>setShowPaymentModal(false)} selectedClassData={selectedClassData} selectedLessonData={null}/>
    }
    </>
  );
};

export default AllClasses;
