import React from "react";

interface CommonButtonProps {
  size?: "tiny"|"extrasmall" | "small" | "medium" | "large";
  model?: "default" | "blue"|"red";
  text: string;
  icon?: React.ElementType;
  onClick?: (e:React.FormEvent<HTMLButtonElement>) => void;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  size = "medium",
  model = "default",
  text,
  icon: Icon,
  onClick,
}) => {
  const handleButtonClick = (e: React.FormEvent<HTMLButtonElement>) => {
    onClick && onClick(e);
  };

  const getButtonStyle = () => {
    switch (model) {
      case "blue":
        return `text-white bg-custom-darkblue hover:bg-custom-hoverdarkblue`;
        case "red":
          return `text-white bg-red-700 hover:bg-red-800`;
      default:
        return `text-black bg-custom-yellow hover:bg-custom-hoveryellow`;
    }
  };

  const getButtonSize = () => {
    switch (size) {
      case "tiny":
        return `p-1 md:p-2`;
      case "extrasmall":
        return `px-2 py-1 md:px-4 md:py-2`;
      case "small":
        return `px-5 py-2 md:px-10 md:py-3 md:min-w-36 min-w-24`;
      case "large":
        return `px-20 py-2 md:px-20 md:py-4`;
      default:
        return `px-10 py-4 md:px-10 md:py-4`;
    }
  };

  return (
    <div>
      <button
        className={`m-2 text-sm font-bold ${getButtonStyle()} rounded-lg flex justify-center items-center`}
        onClick={handleButtonClick}
      >
        <div className={getButtonSize()}>
          <span className="flex justify-center items-center">
          <span>{text}</span>
          </span>
        </div>
      </button>
    </div>
  );
};

export default CommonButton;

