import React, {useEffect, useState } from "react";
import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Sidebar from "../../Ui/Templates/Sidebar";
import Header from "../../Ui/Templates/Header";
import CommonButton from "../../Ui/Atoms/Buttons";
import UpdateProfilePic from "../../Popups/UpdateProfilePic";
import axios from "axios";
import { API_ENDPOINTS } from "../../../services/api_endpoints";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function EditProfile() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({
    user_id: "21355f1d-ace8-4c05-a6e1-2ffcb5c8b206",
    profile_picture: "",
    first_name: "",
    gender:"",
    last_name: "",
    index: "",
    email: "",
    examination_year: "",
    school: "",
    mobile_number: "",
    house_name_or_number: "",
    line1: "",
    line2: "", 
    city: "",
  });
  const [userProfileErrors, setUserProfileErrors] = useState({
    first_name: "",
    gender:"",
    last_name: "",
    index: "",
    email: "",
    examination_year: "",
    school: "",
    mobile_number: "",
    house_name_or_number: "",
    city: "",
  });
  const [showUpdateProfilePic, setShowUpdateProfilePic] = useState(false);


  const handleUpdateProfilePic = (
    show: boolean | ((prevState: boolean) => boolean)
  ) => {
    setShowUpdateProfilePic(show);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try{
        const response = await axios.get(API_ENDPOINTS.GET_USER_DETAILS,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
          },
        });
        setUserProfile(response.data.data);
        
      }
      catch(err){
        console.log(err);
      }
    }
    fetchUserProfile();
  }, []);

  const validateUserDetails = () => {
    let isValid = true;
    const errors = {
      first_name: "",
      gender:"",
      last_name: "",
      index: "",
      email: "",
      school: "",
      examination_year: "",
      mobile_number: "",
      house_name_or_number: "",
      city: "",
    };
    if(userProfile.first_name === ""){
      errors.first_name = "First name is required";
      isValid = false;
    }
    if(userProfile.last_name === ""){
      errors.last_name = "Last name is required";
      isValid = false;
    }
    if(userProfile.email === ""){
      errors.email = "Email is required";
      isValid = false;
    }
    if(userProfile.school === ""){
      errors.school = "School is required";
      isValid = false;
    }
    if(userProfile.mobile_number === ""){
      errors.mobile_number = "Mobile number is required";
      isValid = false;
    }
    if(userProfile.house_name_or_number === ""){
      errors.house_name_or_number = "House name or number is required";
      isValid = false;
    }
    if(userProfile.city === ""){
      errors.city = "City is required";
      isValid = false;
    }
    setUserProfileErrors(errors);
    return isValid;
  }
      



  const updateUserDetails = async () => {
    if(validateUserDetails()){
      try{
        const requestBody = {
          registration_number: 1,
          user_id: userProfile.user_id,
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          email: userProfile.email,
          mobile_number: userProfile.mobile_number,
          examination_year: userProfile.examination_year,
          gender: userProfile.gender,
          school: userProfile.school,
          stream_id: null,
          address: {
            house_name_or_number: userProfile.house_name_or_number,
            line1: userProfile.line1,
            line2: userProfile.line2,
            city: userProfile.city,
          }
        }
      const jsonBlob = new Blob([JSON.stringify(requestBody)], { type: "application/json" });
        const response = await axios.put(API_ENDPOINTS.UPDATE_USER_DETAILS,jsonBlob,{
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authenticationToken")}`,
            "Content-Type": "application/json",
          },
        });
        console.log(response);
        toast.success("User details updated successfully");
        setTimeout(() => {
          navigate("/userprofile");
        }, 2000);
      }
      catch(err){
        console.log(err);
      }
    }

  };

  return (
    <>
    <ToastContainer />
    <div className=" fle">
      <Header />
      <div className="fixed w-full h-full">
        <div className="relative flex h-full">
          <Sidebar />
          <div className="w-full overflow-y-auto">
            {/* Scrollable Content */}
            <div></div>
            <div className="min-h-screen p-4">
              <h1 className="pb-4 text-xl font-bold text-custom-darkblue">
                User Profile
              </h1>
              {/* Profile and name */}
              <div className="rounded-lg bg-custom-yellow">
                <div className="flex p-3 ">
                  <div className="flex items-center justify-center basis-auto">
                    <img
                      src={userProfile.profile_picture ? ('data:image/png;base64,'+userProfile.profile_picture) : `${process.env.PUBLIC_URL}/images/noprofile.png`}
                      alt="Profile"
                      className="w-[25vw] h-[25vw] sm:w-[15vw] sm:h-[15vw] xl:w-[10vw] xl:h-[10vw] rounded-full cursor-pointer hover:opacity-90"
                      onClick={() =>handleUpdateProfilePic(true)}
                      />
                  </div>
                  <div className="flex items-center justify-center ">
                    <div className="my-[9vw] sm:my-[4vw] xl:my-[2vw] mx-4 text-xl md:text-2xl font-semibold text-custom-darkblue pl-5">
                      {userProfile.first_name} {userProfile.last_name}
                      <div className="font-normal ">{userProfile.index}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Other details */}
              <div className="flex-1 pt-4 rounded-lg">
                <h1 className="text-lg font-semibold text-custom-darkblue ">
                  User details
                </h1>
                <form>
                  <div className="py-2 text-base">
                    <div className="font-medium ">First name</div>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      placeholder={userProfile.first_name}
                      value={userProfile.first_name}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          first_name: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.first_name && <div className="text-red-500">{userProfileErrors.first_name}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Last name</div>
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      placeholder={userProfile.last_name}
                      value={userProfile.last_name}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          last_name: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.last_name && <div className="text-red-500">{userProfileErrors.last_name}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Email</div>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder={userProfile.email}
                      value={userProfile.email}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          email: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                      />
                    {
                      userProfileErrors.email && <div className="text-red-500">{userProfileErrors.email}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Gender</div>
                    <input
                      type="text"
                      id="gender"
                      name="gender"
                      placeholder={userProfile.gender}
                      value={userProfile.gender}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          gender: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfile.gender && <div className="text-red-500">{userProfileErrors.gender}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Mobile number</div>
                    <input
                      type="text"
                      id="mobileno"
                      name="mobileno"
                      placeholder={userProfile.mobile_number}
                      value={userProfile.mobile_number}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          mobile_number: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.mobile_number && <div className="text-red-500">{userProfileErrors.mobile_number}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">School</div>
                    <input
                      type="text"
                      id="school"
                      name="school"
                      placeholder={userProfile.school}
                      value={userProfile.school}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          school: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.school && <div className="text-red-500">{userProfileErrors.school}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Examination Year</div>
                    <input
                      type="text"
                      id="examination_year"
                      name="examination_year"
                      placeholder={userProfile.examination_year}
                      value={userProfile.examination_year}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          examination_year: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.school && <div className="text-red-500">{userProfileErrors.school}</div>
                    }
                  </div>
                  <div className="py-2 text-base">
                    <div className="font-medium ">Address</div>
                    <div className="py-2 text-sm ">Address No</div>
                    <input
                      type="text"
                      id="addressNo"
                      name="addressNo"
                      placeholder={userProfile.house_name_or_number}
                      value={userProfile.house_name_or_number}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          house_name_or_number: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    {
                      userProfileErrors.house_name_or_number && <div className="text-red-500">{userProfileErrors.house_name_or_number}</div>
                    }
                    <div className="py-2 text-sm ">Address Line 01</div>
                     <input
                      type="text"
                      id="addressLine01"
                      name="addressLine01"
                      placeholder={userProfile.line1}
                      value={userProfile.line1}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          line1: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                    />
                    <div className="py-2 text-sm ">Address Line 02</div>
                     <input
                      type="text"
                      id="addressLine02"
                      name="addressLine02"
                      placeholder={userProfile.line2}
                      value={userProfile.line2}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          line2: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                      />
                    <div className="py-2 text-sm ">Nearst city</div>
                     <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder={userProfile.city}
                      value={userProfile.city}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          city: e.target.value,
                        })
                      }
                      className="w-full p-2 pl-4 mt-1 border rounded-md bg-slate-100"
                      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}
                      required
                      />
                    {
                      userProfileErrors.city && <div className="text-red-500">{userProfileErrors.city}</div>
                    }
                  </div>
                </form>

                <div>
                  <CommonButton
                    size="medium"
                    model="blue"
                    text="Save"
                    onClick={updateUserDetails}
                    />
                </div>
              </div>
            </div>
            <div className="">
              <FooterWithSitemap />
            </div>
          </div>
        </div>
      </div>
      <UpdateProfilePic
        setUserProfile={setUserProfile}
        show={showUpdateProfilePic}
        onClose={() => handleUpdateProfilePic(false)}
        />
    </div>
    </>
  );
}
