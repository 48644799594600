import React from "react";

type ClassItemProps = {
  name: string;
  image: string;
  classFee: number;
};

const ClassItem: React.FC<ClassItemProps> = ({ name,image,classFee }) => {
  const [imageData, setImageData] = React.useState<string | null>(null);


  React.useEffect(() => {
    if (image) {
      setImageData(`data:image/png;base64,${image}`);
    }
  }, [image]);


  return (
    <div className="flex flex-col items-center bg-white border-[0.5px] border-blue-100 rounded-md cursor-pointer shadow-xl shadow-blue-100 aspect-square
     hover:bg-[#488ac3] transition-all duration-200 hover:scale-105  w-full relative xl:min-h-[20vh] md:min-h-[10vh] p-2 py-8">
      <div className="flex w-full h-4/5 rounded-md">
        <img src={imageData ? imageData : `${process.env.PUBLIC_URL}/images/student-with-graduation-diploma1-min.png`} alt="" className="w-full h-full rounded-md object-cover"/>
      </div>
      <div className="flex flex-col  h-1/5 w-full p-2 font-sans text-black">
        <p className="text-xl font-bold">
        {name}
        </p>
        <p className="text-sm">
         ClassFee: {classFee}
        </p>
      </div>
    </div>
  );
};

export default ClassItem;
