import { FooterWithSitemap } from "../../Ui/Templates/Footer";
import Sidebar from "../../Ui/Templates/Sidebar";
import Header from "../../Ui/Templates/Header";
import { useEffect, useState } from "react";
import { MyQuizData } from "../../../database/quizdata";
import { useSelector, useDispatch } from "react-redux";
import {
    setSelectedOption,
    nextQuestion,
    previousQuestion,
    startQuiz,
    finishQuiz,
    gotoStart,
} from "../../../slice/quizSlice";

export default function QuizzesPages() {
        const dispatch = useDispatch();
        const { currentQuizIndex, quizFinished, marks, selectedOptions } = useSelector((state: any) => state.quiz);
        const [loading, setLoading] = useState(true);

        useEffect(() => {
                setTimeout(() => {
                        setLoading(false);
                }, 1000);
        }, []);

        const handleStartQuiz = () => {
                dispatch(startQuiz());
        };
        const handleFinishQuiz = () => {
                dispatch(finishQuiz(calculateMarks()));
        };

        const handleGotoStart = () => {
                dispatch(gotoStart());
        }

        const calculateMarks = () => {
            let totalMarks = 0;
        
            selectedOptions.forEach((selection: { questionIndex: number; answers: string[] }) => {
                const question: Question = MyQuizData.questions[selection.questionIndex];
                const correctAnswers: string[] = question.options.filter((option) => option.correct).map((option) => option.optionText);
                const selectedAnswers: string[] = selection.answers;
                const correct: boolean = correctAnswers.length === selectedAnswers.length && correctAnswers.every((value) => selectedAnswers.includes(value));
                totalMarks += correct ? question.marks : 0;
            });
        
            return totalMarks;
        };

        return (
            <div className="flex-1">
                    <Header />
                    <div className="fixed w-full h-full">
                    <div className="relative flex h-full">
                            <Sidebar />
                            <div className="w-full overflow-y-auto md:pt-0">
                                <div className="p-10 w-full min-h-screen bg-gray-200">
                                    <div className="pl-2 lg:flex">
                                        <div className="mr-5 basis-auto lg:basis-full p-4 md:p-8 bg-white rounded-xl min-h-screen">
                                                {loading ? (
                                                        <div>Loading...</div>
                                                ) : (
                                                    <>
                                                        <div className="flex flex-wrap gap-4 my-4">
                                                            {currentQuizIndex === -1 && (
                                                                <div className="flex flex-col gap-4 items-center w-full">
                                                                    <h1 className="text-3xl text-center font-bold text-custom-darkblue">
                                                                        Welcome to the Quiz
                                                                    </h1>
                                                                    <button
                                                                            onClick={handleStartQuiz}
                                                                            className="bg-custom-yellow px-10 py-2 rounded-md w-max"
                                                                    >
                                                                            Start Quiz
                                                                    </button>
                                                                    <button className="bg-custom-yellow py-2 px-3  rounded-md">
                                                                            View Leaderboard
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {currentQuizIndex >= 0 && !quizFinished && (
                                                                <QuizDetails
                                                                        currentQuizIndex={currentQuizIndex}
                                                                        handleFinishQuiz={handleFinishQuiz}
                                                                />
                                                        )}
                                                        {(quizFinished && currentQuizIndex !== -1) && (
                                                            <div>
                                                                <h1 className="text-3xl font-bold text-custom-darkblue">
                                                                    Quiz Finished!
                                                                </h1>
                                                                <p className="text-lg text-custom-darkblue">
                                                                    Your Marks: {marks}
                                                                </p>
                                                                <button
                                                                    onClick={handleGotoStart}
                                                                    className="bg-custom-yellow p-2 rounded-md"
                                                                >
                                                                    Finish Quiz
                                                                </button>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <FooterWithSitemap />
                            </div>
                    </div>
                </div>
            </div>
        );
}

interface QuizDetailsProps {
        currentQuizIndex: number;
        handleFinishQuiz: () => void;
}

const QuizDetails: React.FC<QuizDetailsProps> = ({
        currentQuizIndex,
        handleFinishQuiz,
}) => {
        const dispatch = useDispatch();
        const selectedOptions = useSelector((state: any) => state.quiz.selectedOptions);

        const handleOptionChange = (questionIndex: number, optionText: string, questionType: string) => {
                dispatch(setSelectedOption({ questionIndex, optionText, questionType }));
        };

        const handleNextQuiz = () => {
            if (currentQuizIndex === MyQuizData.questions.length - 1) {
            }
            else {
            }
            if (selectedOptions.find((selection: { questionIndex: number; answers: string[] }) => selection.questionIndex === currentQuizIndex)) {
                if (currentQuizIndex < MyQuizData.questions.length - 1) {
                    console.log(selectedOptions.find((selection: { questionIndex: number; answers: string[] }) => selection.questionIndex === currentQuizIndex))
                    dispatch(nextQuestion(currentQuizIndex + 1));
                } else {
                    handleFinishQuiz();
                }
            } else {
                alert("Please select an option to proceed");
            }
        };

        const handlePreviousQuiz = () => {
                dispatch(previousQuestion());
        };

        const currentQuiz = MyQuizData;
        const currentQuestion = MyQuizData.questions[currentQuizIndex];
        return (
                <div>
                        <h1 className="text-3xl font-bold text-custom-darkblue">{currentQuiz.title}</h1>
                        <p className="text-lg text-custom-darkblue">{currentQuiz.description}</p>

                        <div className="flex flex-col gap-4">
                                    <QuestionCard
                                        question={currentQuestion}
                                        questionIndex={currentQuizIndex}
                                        selectedOptions={selectedOptions.find((selection: { questionIndex: number; answers: string[] }) => selection.questionIndex === currentQuizIndex)?.answers || []}
                                        handleOptionChange={handleOptionChange}
                                    />
                                <div className="flex justify-end gap-2 mt-4">
                                        <button onClick={handlePreviousQuiz} className="bg-custom-yellow p-2 rounded-md">
                                                Back
                                        </button>
                                        <button onClick={handleNextQuiz} className="bg-custom-yellow p-2 rounded-md">
                                                Next
                                        </button>
                                </div>
                        </div>
                </div>
        );
};

interface Question {
        questionText: string;
        questionType: string;
        marks: number;
        options: { optionText: string; correct: boolean }[];
        files: { fileUrl: string; fileType: string }[];
}

const QuestionCard = ({
        question,
        questionIndex,
        selectedOptions,
        handleOptionChange,
}: {
        question: Question;
        questionIndex: number;
        selectedOptions: string[];
        handleOptionChange: (questionIndex: number, optionText: string, questionType: string) => void;
}) => (
        <div className="bg-white shadow-sm p-4 rounded-md">
                <p className="text-lg text-custom-darkblue">{question.questionText}</p>
                <div className="flex flex-col gap-2 mt-2">
                        {question.options.map((option, index) => (
                                <OptionCard
                                        key={index}
                                        option={option.optionText}
                                        correct={option.correct}
                                        selected={selectedOptions.includes(option.optionText)}
                                        onChange={() => handleOptionChange(questionIndex, option.optionText, question.questionType)}
                                        questionType={question.questionType}
                                />
                        ))}
                </div>
        </div>
);

interface OptionCardProps {
        option: string;
        correct: boolean;
        selected: boolean;
        onChange: () => void;
        questionType: string;
}

const OptionCard = ({ option, correct, selected, onChange, questionType }: OptionCardProps) => (
        <div
                className={`flex items-center gap-2 p-2 rounded-md cursor-pointer ${
                        selected ? "bg-sky-200"  : "bg-gray-100"
                }`}
                onClick={onChange}
        >
                {questionType === "radio" ? (
                        <input type="radio" checked={selected} readOnly />
                ) : (
                        <input type="checkbox" checked={selected} readOnly />
                )}
                <p className="text-lg text-custom-darkblue">{option}</p>
        </div>
);
