import { Link } from "react-router-dom";
import LessonItem from "../Atoms/LessonsItem";
import { FaEdit } from "react-icons/fa";
import { LessonData } from "../../Pages/OtherPages/Lessons";
import { useSelector } from "react-redux";
import { PiPaypalLogo } from "react-icons/pi";


export default function LessonsOptions({LessonsDataArray,classID,handleNewLessonModal,setSelectedLessonData,handlePaymentModal}: {LessonsDataArray: LessonData[],classID:string|undefined,handleNewLessonModal: () => void,setSelectedLessonData: (lessonData: LessonData) => void,handlePaymentModal: () => void}) {
  const expanded = useSelector(
    (state: { sidebar: { expanded: boolean } }) => state.sidebar.expanded
  );
  return (
    <div>
      <div className={`grid gap-8   ${expanded ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3":"sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"}`}>
        {
          LessonsDataArray?.filter(Lesson=>Lesson.class_id === classID).map((lesson,index) => (
            <div className="flex items-center relative" key={index}>
                <Link to={`/lessonDetails/${lesson.lesson_name}/${lesson.id}`} key={index}>
                  <LessonItem name={lesson.lesson_name} image={lesson.image} price={lesson.price}/>
                </Link>
                <FaEdit className="absolute transition-all duration-200 hover:scale-125 cursor-pointer bottom-7 right-3 text-2xl text-blue-500" 
                  onClick={
                    () => {
                      setSelectedLessonData(lesson);
                      handleNewLessonModal();
                    }}
                    />
                <PiPaypalLogo className="absolute transition-all duration-200 hover:scale-125 cursor-pointer bottom-7 right-10 text-2xl text-blue-500" 
                                onClick={
                                  () => {
                                    setSelectedLessonData(lesson);
                                    handlePaymentModal();
                                  }}/>
              </div>
          ))
        }
      </div>
    </div>
  );
}

// {
//      => (
//       <Link to={`/lessonDetails/${lesson.lesson_name}/${lesson.id}`} key={index} className="w-full h-20 cursor-pointer hover:scale-[0.98] transition-all duration-200 flex items-center px-5 bg-white shadow-md shadow-blue-100 border-[0.5px] border-blue-100 rounded-md">
//       </Link>
//   ))
// }