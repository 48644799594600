

export const API_GATEWAY = `${process.env.REACT_APP_API_GATEWAY}`;


export const API_ENDPOINTS = {
  REGISTER: `${API_GATEWAY}/auth-service/register`,
  LOGIN: `${API_GATEWAY}/auth-service/authenticate`,
  VALIDATE_TOKEN: `${API_GATEWAY}/auth-service/validateToken?`,
  REFRESH_TOKEN: `${API_GATEWAY}/auth-service/refresh?token=`,
  GET_CLASSES: `${API_GATEWAY}/resource-management/class/all`,
  ADD_CLASS: `${API_GATEWAY}/resource-management/class/create`,
  UPDATE_CLASS: `${API_GATEWAY}/resource-management/class/update`,
  GET_CLASS_By_ID: `${API_GATEWAY}/resource-management/class`,
  GET_LESSONS: `${API_GATEWAY}/resource-management/lesson/all`,
  ADD_LESSON: `${API_GATEWAY}/resource-management/lesson/create`,
  UPDATE_LESSON: `${API_GATEWAY}/resource-management/lesson/update`,
  GET_LESSON_By_CLASSID: `${API_GATEWAY}/resource-management/lesson/get-all`,
  GET_ANNOUNCEMENTS: `${API_GATEWAY}/resource-management/announcement/all`,
  DELETE_ANNOUNCEMENT: `${API_GATEWAY}/resource-management/announcement/delete`,
  GET_USER_DETAILS: `${API_GATEWAY}/resource-management/user-details`,
  UPDATE_USER_DETAILS: `${API_GATEWAY}/resource-management/user-details`,
  GET_ALL_SESSIONS_BY_LESSONID: `${API_GATEWAY}/resource-management/session/get-all`,
  ADD_SESSION: `${API_GATEWAY}/resource-management/session/create`,
  UPDATE_SESSION: `${API_GATEWAY}/resource-management/session/update`,
  ADD_MATERIAL: `${API_GATEWAY}/resource-management/material/create`,
  ADD_ANNOUNCEMENT: `${API_GATEWAY}/resource-management/announcement/create`,
  UPDATE_ANNOUNCEMENT: `${API_GATEWAY}/resource-management/announcement/update`,
  CREATE_PAYMENT: `${API_GATEWAY}/payment-service/auth/calculateHash`,
  SAVE_PAYMENT_DETAILS: `${API_GATEWAY}/payment-service/saveContentPayment`,
  UPDATE_USER_PROFILE_PIC: `${API_GATEWAY}/resource-management/user-details/update-profile-picture`,
  GET_ALL_PAPERS: `${API_GATEWAY}/quiz-service/paper/get-all`,
  GET_PAPERS_BYTYPES: `${API_GATEWAY}/quiz-service/paper/get-gy-type/`,
};






