import React, { MouseEvent } from "react";
import Payment from "../Ui/Atoms/Payment";

interface PaymentModalProps {
  onClose: () => void;
  selectedClassData?: any;
  selectedLessonData?: any;
}
const PaymentModal: React.FC<PaymentModalProps> = ({onClose,selectedClassData,selectedLessonData}) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

 

  return (
    <>
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative bg-white w-[70vw] md:w-[60vw] lg:w-[50vw] xl:w-[35vw]  xl:h-auto shadow-lg rounded-3xl">
            <div className="p-5 md:p-10 lg:p-12">
              <div className="px-10 md:px-12">
                <img
                  src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                  alt="Logo"
                  className="w-80 mx-auto"
                />
              </div>
              <form className="items-center justify-center text-center">
                <h1 className="text-3xl font-bold text-custom-darkblue">
                    Make Payment
                </h1>
                <div className="text-left my-6">
                    <h2 className="text-md font-bold mb-2">Payment Details</h2>
                    <div className="px-2 py-3  bg-[#F1C231] rounded-lg">
                        <h3 className="text-sm font-semibold">{selectedClassData !==null ? "Class Name: ":"Lesson Name: " }<span className="text-sm font-normal">{selectedClassData?.class_name || selectedLessonData?.lesson_name }</span></h3>
                        <h3 className="text-sm font-semibold pt-2">Amount: <span className="text-sm font-normal">{selectedClassData?.class_fees || selectedLessonData?.price }</span></h3>
                    </div>
                    <div className="mt-5 flex items-center">
                    <div className="w-5 h-5 flex items-center justify-center bg-red-500 rounded-full">
                        <div className="w-2 h-2 bg-white rounded-full"/>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/images/payhere.png`} alt="payhereImg" className="w-28"/>
                    </div>
                </div>
                <div className="mx-auto ">
                <Payment
                  firstname="John"
                  lastname="Doe"
                  email="johnDoe@gmail.com"
                  paymentTitle={`${selectedClassData?.class_name || selectedLessonData?.lesson_name} Payment`}
                  amount={selectedClassData?.class_fees || selectedLessonData?.price}
                  sendUserId="123"
                  reciveUserID="456"
                  setPaymentSuccess={() => {}}
                  setOrderID={() => {}}
                  onClose={onClose}
                />
                </div>
              </form>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default PaymentModal;

