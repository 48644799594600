import React, { MouseEvent,  useEffect,  useState } from "react";
import CommonButton from "../Ui/Atoms/Buttons";
import axios from "axios";
import { API_ENDPOINTS } from "../../services/api_endpoints";
import { toast, ToastContainer } from "react-toastify";
import { selectedAnnouncement } from "../Pages/Announcements/AnnouncementsPage";

interface AddAnnouncementModalProps {
  onClose: () => void;
  selectedClass: string; // Receive selected class ID
  selectedAnnouncement:selectedAnnouncement;
}

// interface ClassData {
//   id: string;
//   year: number;
//   class_name: string;
//   content_access_type: string;
// }

const AddAnnuncementModal: React.FC<AddAnnouncementModalProps> = ({
  onClose,
  selectedClass,
  selectedAnnouncement
}) => {
  const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const [title, setTitle] = useState<string>(""); // Title for the announcement
  const [description, setDescription] = useState<string>(""); // Description for the announcement
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if(selectedAnnouncement){
      setTitle(selectedAnnouncement.title);
      setDescription(selectedAnnouncement.description);
    }else{
      setTitle("");
      setDescription("");
    }
  }, [selectedAnnouncement]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError(null);
      const requestBody = {
        title: title.trim(),
        description: description.trim(),
      };
      const token = localStorage.getItem("authenticationToken");
      if (!token) {
        setError("Authentication token is missing. Please log in.");
        return;
      }
      if( selectedAnnouncement.id === ""){
        if (!selectedClass) {
          alert("No class selected. Please select a class before adding an announcement.");
          return;
        }
    
        if (!title.trim() || !description.trim()) {
          alert("Please fill in both the title and description.");
          return;
        }
    
        
      const response = await axios.post(API_ENDPOINTS.ADD_ANNOUNCEMENT+`/${selectedClass}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
        
      );
      const result = response.data;
      if (result.meta.message === 'Success') {
        console.log("Announcement created successfully:", result);
        setTitle("");
        setDescription("");
        toast.success("Announcement created successfully");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setError(result.error || "Failed to create announcement.");
        toast.error("Failed to create announcement.");
      }
    }else{
      const response = await axios.patch(API_ENDPOINTS.UPDATE_ANNOUNCEMENT+`/${selectedAnnouncement.id}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
        
      );
      const result = response.data;
      if (result.meta.message === 'Success') {
        console.log("Announcement updated successfully:", result);
        setTitle("");
        setDescription("");
        toast.success("Announcement updated successfully");
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setError(result.error || "Failed to update announcement.");
        toast.error("Failed to update announcement.");
      }
    }
    } catch (err) {
      if( selectedAnnouncement.id === ""){
        setError("An error occurred while submitting the announcement.");
        toast.error("An error occurred while submitting the announcement.");
      }
      else{
        setError("An error occurred while updating the announcement.");
        toast.error("An error occurred while updating the announcement.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
     <ToastContainer />
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={handleOverlayClick}
          ></div>
          <div className="relative p-6 bg-white w-[90vw] sm:w-[75vw] md:w-[65vw] lg:w-[50vw] xl:w-[40vw] sm:h-[80vh] lg:h-[85vh]  shadow-lg rounded-3xl overflow-y-auto">
            <div className="px-10 md:px-20">
              <img
                src={`${process.env.PUBLIC_URL}/images/IT_Gura_logo.png`}
                alt="Logo"
                className="scale-50 mb-4"
              />
            </div>
            <h2 className="text-lg font-semibold text-center text-blue-700 mb-4">
              New Announcement
            </h2>

            {/* Error message */}
            {error && (
              <div className="mb-4 text-red-500 text-center">{error}</div>
            )}

            <form className="px-4" onSubmit={handleSubmit}>
              {/* Title Input */}
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-sm font-semibold text-gray-600 mb-1"
                >
                  Announcement Title
                </label>
                <input
                  id="title"
                  value={title}
                  onChange={handleTitleChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  type="text"
                  placeholder="Enter announcement title"
                />
              </div>

              {/* Description Input */}
              <div className="mb-4">
                <label
                  htmlFor="description"
                  className="block text-sm font-semibold text-gray-600 mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={handleDescriptionChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  rows={4}
                  placeholder="Enter announcement description"
                ></textarea>
              </div>

              {/* Submit Buttons */}
              <div className="flex justify-center space-x-4">
                {
                 ( selectedAnnouncement.title ==="" && selectedAnnouncement.description ==="") ?
                 <button type="submit" disabled={loading}
                 >
                    <CommonButton
                      size="small"
                      model="blue"
                      text={loading ? "Submitting..." : "Set"}
                    />
                  </button>:
                  <button type="button" disabled={loading}>
                    <CommonButton size="small" model="blue" text={loading ? "Updating..." : "Update"} />
                  </button>
                  
                }
                <button type="button" onClick={onClose} disabled={loading}>
                  <CommonButton size="small" text="Cancel" />
                </button>
              </div>
            </form>
          </div>
        </div>
    </>
  );
};

export default AddAnnuncementModal;
