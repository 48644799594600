import { FaDownload } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface PaperContainerProps {
    name: string;
    url: string;
  }
  
  export function PaperContainer({ name, url }: PaperContainerProps) {
    return (
      <div className="flex items-center justify-between p-4 border-b rounded-md hover:bg-custom-yellow">
        <button className="mr-4 text-red-500">Delete</button>
        <h2 className="flex-1 text-custom-darkblue">{name}</h2>
        <a href={url} download className="text-blue-900 cursor-pointer">
          <FaDownload />
        </a>
      </div>
    );
  }

  interface QuizContainerProps {
    name: string;
    url: string;
  }
  
  export function QuizContainer({ name, url }: QuizContainerProps) {
    return (
      <div className="flex items-center justify-between p-4 border-b rounded-md hover:bg-custom-yellow">
        <button className="mr-4 text-red-500">Delete</button>
        <h2 className="flex-1 text-custom-darkblue">{name}</h2>
        <Link to={url} download className="text-blue-900 cursor-pointer">
        <button className="mr-4 text-red-500">Attempt</button>
        </Link>
      </div>
    );
  }
  